import React from 'react';
import { MapPin, Users, Star } from 'lucide-react';

const tours = [
  {
    id: 1,
    name: 'Kapadokya Turu',
    image: 'https://images.unsplash.com/photo-1524231757912-21f4fe3a7200',
    bookings: 45,
    rating: 4.8,
    revenue: 24500
  },
  {
    id: 2,
    name: 'İstanbul Boğaz Turu',
    image: 'https://images.unsplash.com/photo-1527838832700-5059252407fa',
    bookings: 38,
    rating: 4.9,
    revenue: 19800
  },
  {
    id: 3,
    name: 'Pamukkale Turu',
    image: 'https://images.unsplash.com/photo-1590071089561-2087ff1fc868',
    bookings: 32,
    rating: 4.7,
    revenue: 16500
  }
];

export default function PopularTours() {
  return (
    <div className="space-y-4">
      {tours.map((tour) => (
        <div key={tour.id} className="flex items-center space-x-4 p-4 bg-gray-50 rounded-lg">
          <img
            src={tour.image}
            alt={tour.name}
            className="w-16 h-16 rounded-lg object-cover"
          />
          <div className="flex-1">
            <h3 className="font-medium text-gray-900">{tour.name}</h3>
            <div className="flex items-center space-x-4 mt-1 text-sm text-gray-500">
              <div className="flex items-center">
                <Users className="h-4 w-4 mr-1" />
                {tour.bookings} rezervasyon
              </div>
              <div className="flex items-center">
                <Star className="h-4 w-4 mr-1 text-yellow-400" />
                {tour.rating}
              </div>
            </div>
          </div>
          <div className="text-right">
            <div className="text-sm font-medium text-gray-900">
              ₺{tour.revenue.toLocaleString()}
            </div>
            <div className="text-sm text-gray-500">Gelir</div>
          </div>
        </div>
      ))}
    </div>
  );
}