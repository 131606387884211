import React from 'react';
import { Link } from 'react-router-dom';
import { MapPin, Camera, Heart, Star, Calendar } from 'lucide-react';
import { CityData } from '../../../types';

interface CityCardProps {
  city: CityData;
}

export default function CityCard({ city }: CityCardProps) {
  const attractionsCount = Object.values(city.attractions).flat().length;
  
  return (
    <Link
      to={`/city/${city.id}`}
      className="group bg-white rounded-xl shadow-sm hover:shadow-xl transition-all duration-300 overflow-hidden"
    >
      <div className="relative aspect-[4/3]">
        <img
          src={city.images.main}
          alt={city.name}
          className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-500"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent" />
        
        <div className="absolute top-4 right-4 flex space-x-2">
          <button className="p-2 bg-white/90 backdrop-blur-sm rounded-full hover:bg-white transition-colors">
            <Heart className="h-4 w-4 text-red-500" />
          </button>
        </div>

        <div className="absolute top-4 left-4">
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-white/90 backdrop-blur-sm text-blue-600">
            <MapPin className="h-3.5 w-3.5 mr-1" />
            {city.region}
          </span>
        </div>

        <div className="absolute bottom-4 left-4 right-4">
          <h3 className="text-2xl font-bold text-white mb-2">{city.name}</h3>
          <p className="text-sm text-white/90 line-clamp-2">{city.description}</p>
        </div>
      </div>

      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center text-yellow-500">
            <Star className="h-5 w-5 fill-current mr-1" />
            <span className="font-medium">4.8</span>
            <span className="text-sm text-gray-500 ml-1">(120 değerlendirme)</span>
          </div>
          <div className="flex items-center text-gray-500 text-sm">
            <Calendar className="h-4 w-4 mr-1" />
            <span>En iyi sezon: {city.bestTimeToVisit.season}</span>
          </div>
        </div>

        <div className="flex flex-wrap gap-2">
          {Object.values(city.attractions).flat().slice(0, 3).map((attraction, index) => (
            <span
              key={index}
              className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm flex items-center"
            >
              <Camera className="h-3.5 w-3.5 mr-1" />
              {attraction.name}
            </span>
          ))}
          {attractionsCount > 3 && (
            <span className="px-3 py-1 bg-gray-50 text-gray-600 rounded-full text-sm">
              +{attractionsCount - 3} daha
            </span>
          )}
        </div>
      </div>
    </Link>
  );
}