import React from 'react';
import { Users, UserCheck, UserX, UserMinus } from 'lucide-react';
import { UserStats as UserStatsType } from '../../types/user';

interface UserStatsProps {
  stats: UserStatsType;
}

export default function UserStats({ stats }: UserStatsProps) {
  const statItems = [
    {
      label: 'Toplam Üye',
      value: stats.total,
      icon: Users,
      color: 'bg-blue-500',
    },
    {
      label: 'Aktif Üyeler',
      value: stats.active,
      icon: UserCheck,
      color: 'bg-green-500',
    },
    {
      label: 'Pasif Üyeler',
      value: stats.inactive,
      icon: UserX,
      color: 'bg-yellow-500',
    },
    {
      label: 'Engelli Üyeler',
      value: stats.blocked,
      icon: UserMinus,
      color: 'bg-red-500',
    },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      {statItems.map((item) => (
        <div key={item.label} className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center">
            <div className={`p-3 rounded-lg ${item.color}`}>
              <item.icon className="h-6 w-6 text-white" />
            </div>
            <div className="ml-5 w-0 flex-1">
              <dl>
                <dt className="text-sm font-medium text-gray-500 truncate">
                  {item.label}
                </dt>
                <dd className="text-lg font-semibold text-gray-900">
                  {item.value}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}