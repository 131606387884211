import React from 'react';
import { Filter } from 'lucide-react';
import ExperienceHero from '../components/experiences/ExperienceHero';
import ExperienceCategories from '../components/experiences/ExperienceCategories';
import ExperienceGrid from '../components/experiences/ExperienceGrid';

export default function Experiences() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50">
      {/* Hero Section */}
      <ExperienceHero />

      {/* Main Content */}
      <div className="container mx-auto px-4">
        {/* Categories */}
        <div className="mb-16">
          <h2 className="text-2xl font-bold text-gray-900 mb-8">Kategoriler</h2>
          <ExperienceCategories />
        </div>

        {/* Filters */}
        <div className="flex items-center justify-between mb-8">
          <h2 className="text-2xl font-bold text-gray-900">Tüm Deneyimler</h2>
          <div className="flex items-center space-x-4">
            <select className="px-4 py-2 bg-white rounded-lg border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent">
              <option>Tüm Kategoriler</option>
              <option>Macera</option>
              <option>Gastronomi</option>
              <option>Kültür & Sanat</option>
              <option>Fotoğraf</option>
              <option>Doğa</option>
            </select>
            <select className="px-4 py-2 bg-white rounded-lg border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent">
              <option>Fiyat: Tümü</option>
              <option>0₺ - 500₺</option>
              <option>500₺ - 1000₺</option>
              <option>1000₺ - 2000₺</option>
              <option>2000₺+</option>
            </select>
            <select className="px-4 py-2 bg-white rounded-lg border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent">
              <option>Zorluk: Tümü</option>
              <option>Kolay</option>
              <option>Orta</option>
              <option>Zor</option>
            </select>
            <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors">
              <Filter className="h-5 w-5 mr-2" />
              Filtrele
            </button>
          </div>
        </div>

        {/* Experience Grid */}
        <ExperienceGrid />
      </div>
    </div>
  );
}