import React from 'react';
import { MapPin, Search, Compass } from 'lucide-react';
import CitySearchBox from './CitySearchBox';

interface DestinationsHeaderProps {
  searchTerm: string;
  onSearch: (value: string) => void;
}

export default function DestinationsHeader({ searchTerm, onSearch }: DestinationsHeaderProps) {
  return (
    <div className="relative">
      {/* Hero Background */}
      <div className="absolute inset-0 h-[40vh] overflow-hidden">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: 'url(https://images.unsplash.com/photo-1524231757912-21f4fe3a7200)',
            backgroundAttachment: 'fixed'
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/50 to-black/70" />
        </div>
      </div>

      {/* Content */}
      <div className="relative pt-24 pb-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <div className="inline-flex items-center space-x-2 px-4 py-2 bg-white/10 backdrop-blur-sm rounded-full mb-6">
              <Compass className="h-5 w-5 text-blue-400 animate-pulse" />
              <span className="text-white/90">Türkiye'nin En Kapsamlı Şehir Rehberi</span>
            </div>

            <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
              81 İlin Tamamı
            </h1>

            <div className="max-w-xl mx-auto">
              <CitySearchBox value={searchTerm} onChange={onSearch} />
            </div>

            <div className="mt-8 flex justify-center space-x-8">
              <div className="flex items-center text-white/90">
                <MapPin className="h-5 w-5 mr-2" />
                <span>81 İl</span>
              </div>
              <div className="flex items-center text-white/90">
                <Search className="h-5 w-5 mr-2" />
                <span>1000+ Turistik Yer</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Quick Region Filters */}
      <div className="sticky top-20 z-50 bg-white shadow-sm border-t border-gray-100">
        <div className="container mx-auto px-4">
          <div className="flex items-center py-3 -mx-2 overflow-x-auto scrollbar-hide">
            <button className="px-4 py-1.5 text-blue-600 bg-blue-50 rounded-full mx-2 whitespace-nowrap text-sm">
              Tüm Şehirler
            </button>
            <button className="px-4 py-1.5 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-full mx-2 whitespace-nowrap text-sm">
              Marmara
            </button>
            <button className="px-4 py-1.5 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-full mx-2 whitespace-nowrap text-sm">
              Ege
            </button>
            <button className="px-4 py-1.5 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-full mx-2 whitespace-nowrap text-sm">
              Akdeniz
            </button>
            <button className="px-4 py-1.5 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-full mx-2 whitespace-nowrap text-sm">
              İç Anadolu
            </button>
            <button className="px-4 py-1.5 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-full mx-2 whitespace-nowrap text-sm">
              Karadeniz
            </button>
            <button className="px-4 py-1.5 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-full mx-2 whitespace-nowrap text-sm">
              Doğu Anadolu
            </button>
            <button className="px-4 py-1.5 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-full mx-2 whitespace-nowrap text-sm">
              Güneydoğu Anadolu
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}