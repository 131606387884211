import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Plane } from 'lucide-react';
import RegistrationForm from '../components/auth/RegistrationForm';
import SocialLogin from '../components/auth/SocialLogin';
import PasswordStrengthIndicator from '../components/auth/PasswordStrengthIndicator';
import { register } from '../services/auth';
import { validatePassword } from '../utils/security';
import { getValidationErrors } from '../utils/validation';
import { handleError } from '../utils/errorHandling';

export default function Register() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);

  const handleRegister = async (formData: any) => {
    setError('');
    setIsLoading(true);

    try {
      // Validate password strength
      if (!validatePassword(formData.password)) {
        setError('Şifre gereksinimleri karşılanmıyor');
        setIsLoading(false);
        return;
      }

      // Validate form data
      const validationErrors = getValidationErrors(formData);
      if (validationErrors.length > 0) {
        setError(validationErrors.join(', '));
        setIsLoading(false);
        return;
      }

      const result = await register(formData);

      if (result.success) {
        // Redirect to home page with success message
        navigate('/', {
          state: {
            registrationSuccess: true,
            message: 'Kayıt işleminiz başarıyla tamamlandı! Hoş geldiniz.'
          },
          replace: true // Replace current history entry
        });
      } else {
        setError(result.error || 'Kayıt olurken bir hata oluştu.');
      }
    } catch (err) {
      const { message } = handleError(err);
      setError(message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-white to-blue-50">
      <div className="container mx-auto min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex justify-center">
            <div className="flex items-center space-x-2">
              <Plane className="h-8 w-8 text-blue-600" />
              <span className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-cyan-500 bg-clip-text text-transparent">
                Turla
              </span>
            </div>
          </div>
          
          <h2 className="mt-6 text-center text-4xl font-bold tracking-tight text-gray-900">
            Yeni Hesap Oluştur
          </h2>
          <p className="mt-3 text-center text-lg text-gray-600">
            Zaten üye misiniz?{' '}
            <Link 
              to="/login" 
              className="font-semibold text-blue-600 hover:text-blue-500 transition-colors"
            >
              Giriş yapın
            </Link>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
          <div className="bg-white/70 backdrop-blur-lg py-8 px-4 shadow-2xl shadow-gray-200/50 sm:rounded-2xl sm:px-10 border border-gray-100">
            <RegistrationForm
              onSubmit={handleRegister}
              isLoading={isLoading}
              error={error}
              onPasswordChange={(password) => {
                const strength = calculatePasswordStrength(password);
                setPasswordStrength(strength);
              }}
            />
            <PasswordStrengthIndicator strength={passwordStrength} />
            <SocialLogin />
          </div>

          <div className="mt-6 text-center">
            <p className="text-sm text-gray-600">
              Kayıt olarak{' '}
              <Link to="/terms" className="text-blue-600 hover:text-blue-500">
                kullanım şartlarını
              </Link>
              {' '}ve{' '}
              <Link to="/privacy" className="text-blue-600 hover:text-blue-500">
                gizlilik politikasını
              </Link>
              {' '}kabul etmiş olursunuz.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

const calculatePasswordStrength = (password: string): number => {
  let score = 0;
  
  // Length check
  if (password.length >= 8) score += 20;
  if (password.length >= 12) score += 10;
  
  // Character variety
  if (/[A-Z]/.test(password)) score += 20;
  if (/[a-z]/.test(password)) score += 20;
  if (/[0-9]/.test(password)) score += 20;
  if (/[^A-Za-z0-9]/.test(password)) score += 10;
  
  return Math.min(100, score);
};