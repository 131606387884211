import React from 'react';
import { Sun, Cloud, Leaf, Snowflake } from 'lucide-react';

const seasons = [
  {
    name: 'İlkbahar',
    icon: Sun,
    color: 'bg-green-50 text-green-600',
    months: 'Mart - Mayıs',
    destinations: [
      'İstanbul - Lale Festivali',
      'Kapadokya - Balon Turları',
      'Efes Antik Kenti',
      'Pamukkale Travertenleri'
    ]
  },
  {
    name: 'Yaz',
    icon: Sun,
    color: 'bg-blue-50 text-blue-600',
    months: 'Haziran - Ağustos',
    destinations: [
      'Antalya Sahilleri',
      'Bodrum Koyları',
      'Fethiye - Ölüdeniz',
      'Çeşme Plajları'
    ]
  },
  {
    name: 'Sonbahar',
    icon: Leaf,
    color: 'bg-orange-50 text-orange-600',
    months: 'Eylül - Kasım',
    destinations: [
      'Karadeniz Yaylaları',
      'Safranbolu Evleri',
      'Amasya Tarihi Kent',
      'Mardin Sokakları'
    ]
  },
  {
    name: 'Kış',
    icon: Snowflake,
    color: 'bg-indigo-50 text-indigo-600',
    months: 'Aralık - Şubat',
    destinations: [
      'Uludağ Kayak Merkezi',
      'Palandöken Kayak',
      'Kartepe Kış Sporları',
      'Kapadokya Kar Manzarası'
    ]
  }
];

export default function SeasonalGuide() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Mevsimsel Gezi Rehberi
          </h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Her mevsim için en ideal rotalar ve öneriler
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {seasons.map((season) => (
            <div
              key={season.name}
              className={`${season.color} rounded-xl p-6 transition-all duration-300 hover:shadow-lg`}
            >
              <div className="flex items-center mb-4">
                <season.icon className="h-8 w-8 mr-3" />
                <div>
                  <h3 className="text-xl font-bold">{season.name}</h3>
                  <p className="text-sm opacity-75">{season.months}</p>
                </div>
              </div>
              
              <ul className="space-y-2">
                {season.destinations.map((destination, index) => (
                  <li key={index} className="flex items-center text-sm">
                    <span className="w-2 h-2 rounded-full bg-current mr-2" />
                    {destination}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}