import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Map, 
  Users, 
  FileText, 
  Database,
  Plus,
  Download,
  Upload,
  Settings
} from 'lucide-react';

const quickActions = [
  {
    name: 'Yeni Tur',
    icon: Map,
    href: '/admincaner01/tours/new',
    color: 'bg-blue-500'
  },
  {
    name: 'Kullanıcı Ekle',
    icon: Users,
    href: '/admincaner01/users/new',
    color: 'bg-green-500'
  },
  {
    name: 'Rapor Oluştur',
    icon: FileText,
    href: '/admincaner01/reports/new',
    color: 'bg-purple-500'
  },
  {
    name: 'Yedekleme',
    icon: Database,
    href: '/admincaner01/database/backup',
    color: 'bg-orange-500'
  }
];

export default function QuickActions() {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
      {quickActions.map((action) => (
        <Link
          key={action.name}
          to={action.href}
          className={`${action.color} text-white p-4 rounded-lg hover:opacity-90 transition-all duration-200 flex flex-col items-center justify-center space-y-2`}
        >
          <action.icon className="h-6 w-6" />
          <span className="text-sm font-medium">{action.name}</span>
        </Link>
      ))}
    </div>
  );
}