import React from 'react';
import { MapPin } from 'lucide-react';
import { getAllCities } from '../data/cities';
import { Link } from 'react-router-dom';

export default function TurkeyMap() {
  const cities = getAllCities();

  return (
    <div className="relative w-full aspect-[16/9] bg-white rounded-xl shadow-lg overflow-hidden">
      <div className="absolute inset-0 bg-blue-50/50"></div>
      
      {cities.map((city) => (
        <Link
          key={city.id}
          to={`/city/${city.id}`}
          className="absolute transform -translate-x-1/2 -translate-y-1/2 group"
          style={{
            left: `${city.coordinates.lng}%`,
            top: `${city.coordinates.lat}%`
          }}
        >
          <div className="relative">
            <MapPin className="h-6 w-6 text-blue-600" />
            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 opacity-0 group-hover:opacity-100 transition-opacity">
              <div className="bg-white px-3 py-2 rounded-lg shadow-lg text-sm whitespace-nowrap">
                <p className="font-medium text-gray-900">{city.name}</p>
                <div className="flex flex-wrap gap-1 mt-1">
                  {Object.values(city.attractions).flat().slice(0, 3).map((attraction, index) => (
                    <span
                      key={index}
                      className="px-2 py-0.5 bg-blue-50 text-blue-600 rounded-full text-xs"
                    >
                      {attraction.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}