import React from 'react';

interface PasswordStrengthIndicatorProps {
  strength: number;
}

export default function PasswordStrengthIndicator({ strength }: PasswordStrengthIndicatorProps) {
  const getStrengthText = () => {
    if (strength >= 80) return 'Çok Güçlü';
    if (strength >= 60) return 'Güçlü';
    if (strength >= 40) return 'Orta';
    if (strength >= 20) return 'Zayıf';
    return 'Çok Zayıf';
  };

  const getStrengthColor = () => {
    if (strength >= 80) return 'bg-green-500';
    if (strength >= 60) return 'bg-blue-500';
    if (strength >= 40) return 'bg-yellow-500';
    if (strength >= 20) return 'bg-orange-500';
    return 'bg-red-500';
  };

  return (
    <div className="mt-4">
      <div className="flex justify-between items-center mb-1">
        <span className="text-sm text-gray-600">Şifre Gücü:</span>
        <span className={`text-sm font-medium ${
          strength >= 60 ? 'text-green-600' :
          strength >= 40 ? 'text-blue-600' :
          strength >= 20 ? 'text-yellow-600' :
          'text-red-600'
        }`}>
          {getStrengthText()}
        </span>
      </div>
      <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
        <div
          className={`h-full ${getStrengthColor()} transition-all duration-300`}
          style={{ width: `${strength}%` }}
        />
      </div>
    </div>
  );
}