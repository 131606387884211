import { CityData } from '../../../../types/city';

export const istanbul: CityData = {
  id: 'istanbul',
  name: 'İstanbul',
  region: 'Marmara',
  population: 15840900,
  description: 'Asya ve Avrupa kıtalarını birleştiren İstanbul, 8500 yıllık tarihi, zengin kültürel mirası ve modern yaşamı mükemmel bir uyumla bir ararda sunar.',
  images: {
    main: 'https://source.unsplash.com/1920x1080/?istanbul,turkey',
    hero: 'https://source.unsplash.com/1920x1080/?bosphorus,istanbul',
    gallery: {
      historical: [
        'https://source.unsplash.com/1920x1080/?hagia-sophia',
        'https://source.unsplash.com/1920x1080/?topkapi-palace',
        'https://source.unsplash.com/1920x1080/?blue-mosque',
        'https://source.unsplash.com/1920x1080/?basilica-cistern'
      ],
      cultural: [
        'https://source.unsplash.com/1920x1080/?grand-bazaar-istanbul',
        'https://source.unsplash.com/1920x1080/?spice-bazaar-istanbul',
        'https://source.unsplash.com/1920x1080/?galata-tower',
        'https://source.unsplash.com/1920x1080/?turkish-coffee'
      ],
      natural: [
        'https://source.unsplash.com/1920x1080/?bosphorus-bridge',
        'https://source.unsplash.com/1920x1080/?princes-islands',
        'https://source.unsplash.com/1920x1080/?belgrad-forest',
        'https://source.unsplash.com/1920x1080/?golden-horn'
      ],
      modern: [
        'https://source.unsplash.com/1920x1080/?istanbul-modern',
        'https://source.unsplash.com/1920x1080/?levent-istanbul',
        'https://source.unsplash.com/1920x1080/?istanbul-airport',
        'https://source.unsplash.com/1920x1080/?istanbul-shopping'
      ]
    }
  },
  attractions: {
    historical: [
      {
        name: 'Ayasofya-i Kebir Camii',
        type: 'historical',
        description: '1500 yıllık tarihi ile dünya mimarlık tarihinin başyapıtlarından biri.',
        image: 'https://source.unsplash.com/1920x1080/?hagia-sophia',
        location: { lat: 41.0086, lng: 28.9802 },
        visitDuration: '2-3 saat',
        entryFee: 0,
        openingHours: '24 saat açık'
      },
      {
        name: 'Topkapı Sarayı',
        type: 'historical',
        description: 'Osmanlı İmparatorluğu\'nun 400 yıl boyunca yönetim merkezi.',
        image: 'https://source.unsplash.com/1920x1080/?topkapi-palace',
        location: { lat: 41.0115, lng: 28.9833 },
        visitDuration: '3-4 saat',
        entryFee: 200,
        openingHours: '09:00-18:00'
      }
    ],
    natural: [
      {
        name: 'Boğaziçi',
        type: 'natural',
        description: 'İstanbul\'un kalbi, iki kıtayı birleştiren eşsiz su yolu.',
        image: 'https://source.unsplash.com/1920x1080/?bosphorus',
        location: { lat: 41.0855, lng: 29.0558 },
        visitDuration: 'Yarım gün - Tam gün',
        openingHours: '24 saat açık'
      }
    ],
    cultural: [
      {
        name: 'Kapalıçarşı',
        type: 'cultural',
        description: 'Dünyanın en eski ve en büyük kapalı çarşılarından biri.',
        image: 'https://source.unsplash.com/1920x1080/?grand-bazaar',
        location: { lat: 41.0108, lng: 28.9682 },
        visitDuration: '3-4 saat',
        openingHours: '09:00-19:00'
      }
    ]
  },
  bestTimeToVisit: {
    season: 'İlkbahar ve Sonbahar',
    months: ['Nisan', 'Mayıs', 'Eylül', 'Ekim'],
    description: 'Ilıman hava ve turist yoğunluğunun az olduğu dönem'
  },
  coordinates: {
    lat: 41.0082,
    lng: 28.9784
  }
};