import React, { useState } from 'react';
import { Search, Star, ThumbsUp, Flag } from 'lucide-react';

export default function Reviews() {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterRating, setFilterRating] = useState('all');

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Yorum Yönetimi</h1>
      </div>

      <div className="mb-6 flex flex-col sm:flex-row gap-4">
        <div className="flex-1 relative">
          <input
            type="text"
            placeholder="Yorum veya kullanıcı ara..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
        </div>
        <select
          value={filterRating}
          onChange={(e) => setFilterRating(e.target.value)}
          className="px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        >
          <option value="all">Tüm Puanlar</option>
          <option value="5">5 Yıldız</option>
          <option value="4">4 Yıldız</option>
          <option value="3">3 Yıldız</option>
          <option value="2">2 Yıldız</option>
          <option value="1">1 Yıldız</option>
        </select>
      </div>

      <div className="space-y-4">
        {/* Sample review */}
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex justify-between items-start">
            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0">
                <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center">
                  <span className="text-blue-600 font-medium">AY</span>
                </div>
              </div>
              <div>
                <div className="flex items-center">
                  <h3 className="text-sm font-medium text-gray-900">Ahmet Yılmaz</h3>
                  <span className="ml-2 text-sm text-gray-500">15 Mart 2024</span>
                </div>
                <div className="flex items-center mt-1">
                  {[...Array(5)].map((_, i) => (
                    <Star
                      key={i}
                      className={`h-4 w-4 ${
                        i < 4 ? 'text-yellow-400 fill-current' : 'text-gray-300'
                      }`}
                    />
                  ))}
                </div>
                <p className="mt-2 text-sm text-gray-600">
                  Kapadokya turu muhteşemdi! Rehberimiz çok bilgiliydi ve program çok iyi planlanmıştı.
                  Özellikle balon turu unutulmazdı. Kesinlikle tavsiye ediyorum.
                </p>
                <div className="mt-4 flex items-center space-x-4">
                  <button className="flex items-center text-sm text-gray-500 hover:text-gray-700">
                    <ThumbsUp className="h-4 w-4 mr-1" />
                    <span>15 kişi faydalı buldu</span>
                  </button>
                  <button className="flex items-center text-sm text-gray-500 hover:text-gray-700">
                    <Flag className="h-4 w-4 mr-1" />
                    <span>Rapor Et</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex space-x-2">
              <button className="px-3 py-1 text-sm text-green-600 hover:text-green-700">
                Onayla
              </button>
              <button className="px-3 py-1 text-sm text-red-600 hover:text-red-700">
                Sil
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}