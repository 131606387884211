import React from 'react';
import { MapPin } from 'lucide-react';

export default function EmptyState() {
  return (
    <div className="text-center py-12">
      <div className="bg-white rounded-lg shadow-sm p-8 max-w-md mx-auto">
        <MapPin className="h-12 w-12 text-gray-400 mx-auto mb-4" />
        <h3 className="text-lg font-semibold text-gray-900 mb-2">
          Şehir Bulunamadı
        </h3>
        <p className="text-gray-500 mb-4">
          Aramanızla eşleşen şehir bulunamadı. Lütfen farklı bir arama yapmayı deneyin.
        </p>
        <button 
          onClick={() => window.location.reload()}
          className="text-blue-600 hover:text-blue-700 font-medium"
        >
          Tüm şehirleri göster
        </button>
      </div>
    </div>
  );
}