import React from 'react';
import ViewToggle from './ViewToggle';

interface ResultsHeaderProps {
  searchTerm: string;
  totalResults: number;
  activeView: 'list' | 'map';
  onViewChange: (view: 'list' | 'map') => void;
}

export default function ResultsHeader({ 
  searchTerm, 
  totalResults, 
  activeView, 
  onViewChange 
}: ResultsHeaderProps) {
  return (
    <div className="flex justify-between items-center mb-8">
      <div>
        <h2 className="text-xl font-bold text-gray-900">
          {searchTerm 
            ? `"${searchTerm}" için ${totalResults} sonuç` 
            : `Tüm Şehirler (${totalResults})`}
        </h2>
        {searchTerm && (
          <p className="text-sm text-gray-500 mt-1">
            Türkiye'nin dört bir yanından keşfedilecek yerler
          </p>
        )}
      </div>
      <ViewToggle activeView={activeView} onViewChange={onViewChange} />
    </div>
  );
}