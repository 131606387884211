import { encryptData, decryptData } from '../utils/security';
import { rateLimit } from '../utils/security';

const ADMIN_SESSION_KEY = 'adminSession';
const ADMIN_CREDENTIALS = {
  username: '31042879262',
  password: 'Caner1605.'
};

export const validateAdminCredentials = async (username: string, password: string) => {
  try {
    // Rate limit kontrolü
    if (!rateLimit.checkLimit('admin_login')) {
      return {
        success: false,
        error: 'Çok fazla başarısız deneme. Lütfen 15 dakika sonra tekrar deneyin.'
      };
    }

    // Şifre kontrolü
    if (username === ADMIN_CREDENTIALS.username && password === ADMIN_CREDENTIALS.password) {
      const session = {
        id: generateSessionId(),
        username,
        role: 'admin',
        timestamp: Date.now(),
        expiresAt: Date.now() + (24 * 60 * 60 * 1000) // 24 saat
      };

      const encryptedSession = encryptData(session);
      localStorage.setItem(ADMIN_SESSION_KEY, encryptedSession);
      localStorage.setItem('adminAuth', 'true');

      // Rate limit sıfırla
      rateLimit.resetLimit('admin_login');

      return { success: true };
    }

    return { 
      success: false, 
      error: 'Geçersiz kullanıcı adı veya şifre' 
    };
  } catch (error) {
    console.error('Admin login error:', error);
    return {
      success: false,
      error: 'Giriş işlemi sırasında bir hata oluştu'
    };
  }
};

export const checkAdminSession = (): boolean => {
  try {
    const adminAuth = localStorage.getItem('adminAuth');
    if (adminAuth !== 'true') return false;

    const encryptedSession = localStorage.getItem(ADMIN_SESSION_KEY);
    if (!encryptedSession) return false;

    const session = decryptData(encryptedSession);
    if (!session) return false;

    // Oturum süresi kontrolü
    if (Date.now() > session.expiresAt) {
      clearAdminSession();
      return false;
    }

    return true;
  } catch (error) {
    console.error('Session check error:', error);
    clearAdminSession();
    return false;
  }
};

export const clearAdminSession = (): void => {
  localStorage.removeItem(ADMIN_SESSION_KEY);
  localStorage.removeItem('adminAuth');
};

const generateSessionId = (): string => {
  const timestamp = Date.now().toString(36);
  const random = Math.random().toString(36).substring(2, 15);
  return `admin-${timestamp}-${random}`;
};