import React from 'react';
import HeroSection from '../components/home/HeroSection';
import GuideCategories from '../components/home/GuideCategories';
import PopularDestinations from '../components/home/PopularDestinations';
import SeasonalGuide from '../components/home/SeasonalGuide';
import TravelTips from '../components/home/TravelTips';
import NewsletterSignup from '../components/NewsletterSignup';

export default function Home() {
  return (
    <div className="min-h-screen">
      <HeroSection />
      <GuideCategories />
      <PopularDestinations />
      <SeasonalGuide />
      <TravelTips />
      <NewsletterSignup />
    </div>
  );
}