import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Plane, Globe, Menu, X, ChevronDown, Bell, Heart,
  MapPin, Calendar, Gift, Sparkles, HelpCircle,
  Phone, Mail, Facebook, Instagram, Twitter
} from 'lucide-react';
import { isAuthenticated, getCurrentUser } from '../services/auth';
import NotificationCenter from './NotificationCenter';

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [showTopBar, setShowTopBar] = useState(true);
  const location = useLocation();
  const currentUser = getCurrentUser();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      {/* Top Bar */}
      {showTopBar && (
        <div className="bg-gradient-to-r from-blue-700 to-blue-900 text-white py-2">
          <div className="container mx-auto px-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-6">
                <div className="flex items-center space-x-4">
                  <a href="tel:+902125550123" className="flex items-center text-sm hover:text-blue-200">
                    <Phone className="h-4 w-4 mr-1" />
                    <span>(0212) 555 0123</span>
                  </a>
                  <a href="mailto:info@turla.com" className="flex items-center text-sm hover:text-blue-200">
                    <Mail className="h-4 w-4 mr-1" />
                    <span>info@turla.com</span>
                  </a>
                </div>
                <div className="hidden md:flex items-center space-x-2">
                  <a href="#" className="hover:text-blue-200 transition-colors">
                    <Facebook className="h-4 w-4" />
                  </a>
                  <a href="#" className="hover:text-blue-200 transition-colors">
                    <Instagram className="h-4 w-4" />
                  </a>
                  <a href="#" className="hover:text-blue-200 transition-colors">
                    <Twitter className="h-4 w-4" />
                  </a>
                </div>
              </div>
              <button 
                onClick={() => setShowTopBar(false)}
                className="text-blue-200 hover:text-white transition-colors"
              >
                <X className="h-4 w-4" />
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Main Header */}
      <header 
        className={`fixed w-full z-50 transition-all duration-300 ${
          isScrolled ? 'bg-white shadow-md' : 'bg-transparent'
        }`}
        style={{ top: showTopBar ? '32px' : '0' }}
      >
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-20">
            {/* Logo */}
            <Link to="/" className="flex items-center space-x-2 group">
              <Plane className={`h-8 w-8 transition-transform group-hover:rotate-12 ${
                isScrolled ? 'text-blue-600' : 'text-white'
              }`} />
              <span className={`text-2xl font-bold ${
                isScrolled ? 'text-gradient' : 'text-white'
              }`}>
                Turla Türkiye
              </span>
            </Link>

            {/* Desktop Navigation */}
            <nav className="hidden lg:flex items-center space-x-8">
              <Link 
                to="/destinations" 
                className={`nav-link flex items-center space-x-1 ${
                  isScrolled ? 'text-gray-700 hover:text-blue-600' : 'text-white hover:text-blue-200'
                }`}
              >
                <MapPin className="h-4 w-4" />
                <span>Destinasyonlar</span>
              </Link>
              <Link 
                to="/tours" 
                className={`nav-link flex items-center space-x-1 ${
                  isScrolled ? 'text-gray-700 hover:text-blue-600' : 'text-white hover:text-blue-200'
                }`}
              >
                <Calendar className="h-4 w-4" />
                <span>Turlar</span>
              </Link>
              <Link 
                to="/experiences" 
                className={`nav-link flex items-center space-x-1 ${
                  isScrolled ? 'text-gray-700 hover:text-blue-600' : 'text-white hover:text-blue-200'
                }`}
              >
                <Heart className="h-4 w-4" />
                <span>Deneyimler</span>
              </Link>
              <Link 
                to="/about" 
                className={`nav-link flex items-center space-x-1 ${
                  isScrolled ? 'text-gray-700 hover:text-blue-600' : 'text-white hover:text-blue-200'
                }`}
              >
                <HelpCircle className="h-4 w-4" />
                <span>Hakkımızda</span>
              </Link>
            </nav>

            {/* Right Section */}
            <div className="hidden lg:flex items-center space-x-6">
              {/* Campaigns */}
              <Link
                to="/campaigns"
                className={`flex items-center space-x-2 ${
                  isScrolled ? 'text-gray-700 hover:text-blue-600' : 'text-white hover:text-blue-200'
                }`}
              >
                <Sparkles className="h-5 w-5" />
                <span>Kampanyalar</span>
              </Link>

              {/* Language Selector */}
              <div className="relative">
                <button
                  onClick={() => setIsLanguageMenuOpen(!isLanguageMenuOpen)}
                  className={`flex items-center space-x-2 ${
                    isScrolled ? 'text-gray-700 hover:text-blue-600' : 'text-white hover:text-blue-200'
                  }`}
                >
                  <Globe className="h-5 w-5" />
                  <span>TR</span>
                  <ChevronDown className={`h-4 w-4 transition-transform duration-200 ${
                    isLanguageMenuOpen ? 'rotate-180' : ''
                  }`} />
                </button>

                {isLanguageMenuOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-xl shadow-xl py-2 animate-fade-in">
                    <button className="w-full px-4 py-2 text-left text-gray-700 hover:text-blue-600 hover:bg-blue-50/50">
                      Türkçe
                    </button>
                    <button className="w-full px-4 py-2 text-left text-gray-700 hover:text-blue-600 hover:bg-blue-50/50">
                      English
                    </button>
                  </div>
                )}
              </div>

              {/* Auth Buttons */}
              {currentUser ? (
                <div className="flex items-center space-x-4">
                  <NotificationCenter />
                  <div className="relative">
                    <button
                      onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
                      className={`flex items-center space-x-2 ${
                        isScrolled ? 'text-gray-700 hover:text-blue-600' : 'text-white hover:text-blue-200'
                      }`}
                    >
                      <div className="w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center">
                        <span className="text-blue-600 font-medium">
                          {currentUser.firstName[0]}{currentUser.lastName[0]}
                        </span>
                      </div>
                      <span>{currentUser.firstName}</span>
                      <ChevronDown className={`h-4 w-4 transition-transform duration-200 ${
                        isProfileMenuOpen ? 'rotate-180' : ''
                      }`} />
                    </button>
                    {/* Profile dropdown menu */}
                  </div>
                </div>
              ) : (
                <div className="flex items-center space-x-4">
                  <Link
                    to="/login"
                    className={`nav-link flex items-center space-x-1 ${
                      isScrolled ? 'text-gray-700 hover:text-blue-600' : 'text-white hover:text-blue-200'
                    }`}
                  >
                    Giriş Yap
                  </Link>
                  <Link
                    to="/register"
                    className={`btn flex items-center space-x-1 ${
                      isScrolled 
                        ? 'bg-blue-600 text-white hover:bg-blue-700'
                        : 'bg-white/20 backdrop-blur-sm text-white hover:bg-white/30'
                    }`}
                  >
                    Kayıt Ol
                  </Link>
                </div>
              )}
            </div>

            {/* Mobile Menu Button */}
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className={`lg:hidden p-2 rounded-lg transition-colors ${
                isScrolled ? 'text-gray-600 hover:bg-gray-100' : 'text-white hover:bg-white/10'
              }`}
            >
              {isMobileMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="lg:hidden bg-white border-t border-gray-100 animate-slide-down">
            {/* Mobile menu content */}
          </div>
        )}
      </header>
    </>
  );
}