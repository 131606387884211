import React from 'react';
import { 
  Users, 
  Map, 
  Calendar, 
  TrendingUp,
  ArrowUpRight,
  ArrowDownRight,
  Star,
  MessageSquare,
  Bell,
  DollarSign,
  Activity,
  BarChart2
} from 'lucide-react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import StatsCard from '../../components/admin/StatsCard';
import RecentUsers from '../../components/admin/RecentUsers';
import PopularTours from '../../components/admin/PopularTours';
import RecentReviews from '../../components/admin/RecentReviews';
import NotificationCenter from '../../components/admin/NotificationCenter';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const revenueData = {
  labels: ['Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt', 'Paz'],
  datasets: [
    {
      label: 'Gelir',
      data: [4500, 5300, 4800, 6000, 5700, 7500, 6800],
      borderColor: '#10b981',
      backgroundColor: 'rgba(16, 185, 129, 0.1)',
      fill: true,
      tension: 0.4
    },
    {
      label: 'Hedef',
      data: [5000, 5000, 5000, 5000, 5000, 5000, 5000],
      borderColor: '#6b7280',
      borderDash: [5, 5],
      fill: false
    }
  ]
};

const bookingData = {
  labels: ['Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt', 'Paz'],
  datasets: [{
    label: 'Rezervasyonlar',
    data: [12, 19, 15, 22, 28, 35, 30],
    backgroundColor: '#6366f1',
    borderRadius: 6
  }]
};

export default function Dashboard() {
  const stats = [
    {
      title: 'Toplam Üye',
      value: '1,234',
      change: '+12%',
      icon: Users,
      color: 'blue',
      details: {
        today: '15 yeni üye',
        thisWeek: '89 yeni üye',
        thisMonth: '234 yeni üye'
      }
    },
    {
      title: 'Aktif Turlar',
      value: '45',
      change: '+5%',
      icon: Map,
      color: 'green',
      details: {
        active: '38 tur',
        upcoming: '7 tur',
        completed: '156 tur'
      }
    },
    {
      title: 'Bu Ay Rezervasyon',
      value: '89',
      change: '+18%',
      icon: Calendar,
      color: 'purple',
      details: {
        pending: '12 beklemede',
        confirmed: '68 onaylandı',
        cancelled: '9 iptal'
      }
    },
    {
      title: 'Toplam Gelir',
      value: '₺156,890',
      change: '+22%',
      icon: TrendingUp,
      color: 'yellow',
      details: {
        today: '₺4,560',
        thisWeek: '₺28,900',
        thisMonth: '₺156,890'
      }
    }
  ];

  const quickActions = [
    { name: 'Yeni Tur Ekle', icon: Map, color: 'bg-blue-500' },
    { name: 'Rezervasyon Yönet', icon: Calendar, color: 'bg-purple-500' },
    { name: 'Yorumları Kontrol Et', icon: MessageSquare, color: 'bg-yellow-500' },
    { name: 'Bildirimleri Gör', icon: Bell, color: 'bg-red-500' }
  ];

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false
        }
      },
      x: {
        grid: {
          display: false
        }
      }
    }
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Yönetim Paneli</h1>
          <p className="text-gray-600">Hoş geldiniz, bugünün özeti aşağıdadır.</p>
        </div>
        <div className="flex space-x-3">
          {quickActions.map((action) => (
            <button
              key={action.name}
              className={`${action.color} text-white p-2 rounded-lg hover:opacity-90 transition-opacity`}
              title={action.name}
            >
              <action.icon className="h-5 w-5" />
            </button>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {stats.map((stat, index) => (
          <StatsCard key={index} {...stat} />
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Gelir Grafiği</h2>
            <select className="text-sm border-gray-300 rounded-md">
              <option>Son 7 gün</option>
              <option>Son 30 gün</option>
              <option>Son 3 ay</option>
            </select>
          </div>
          <div className="h-64">
            <Line data={revenueData} options={chartOptions} />
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Rezervasyon İstatistikleri</h2>
            <select className="text-sm border-gray-300 rounded-md">
              <option>Günlük</option>
              <option>Haftalık</option>
              <option>Aylık</option>
            </select>
          </div>
          <div className="h-64">
            <Bar data={bookingData} options={chartOptions} />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-8">
        <div className="lg:col-span-2 bg-white rounded-lg shadow-sm p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Son Kayıt Olan Üyeler</h2>
            <button className="text-blue-600 hover:text-blue-700 text-sm font-medium">
              Tümünü Gör
            </button>
          </div>
          <RecentUsers />
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Bildirimler</h2>
            <button className="text-blue-600 hover:text-blue-700 text-sm font-medium">
              Tümünü Gör
            </button>
          </div>
          <NotificationCenter />
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Popüler Turlar</h2>
            <button className="text-blue-600 hover:text-blue-700 text-sm font-medium">
              Tümünü Gör
            </button>
          </div>
          <PopularTours />
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Son Yorumlar</h2>
            <button className="text-blue-600 hover:text-blue-700 text-sm font-medium">
              Tümünü Gör
            </button>
          </div>
          <RecentReviews />
        </div>
      </div>
    </div>
  );
}