export const resizeImage = (file: File, maxWidth: number, maxHeight: number): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target?.result as string;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = Math.round((width * maxHeight) / height);
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error('Canvas to Blob conversion failed'));
            }
          },
          file.type,
          0.7
        );
      };
      img.onerror = reject;
    };
    reader.onerror = reject;
  });
};

export const saveImageToLocalStorage = async (userId: string, imageFile: File) => {
  try {
    const resizedBlob = await resizeImage(imageFile, 400, 400);
    const reader = new FileReader();
    
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        const base64String = reader.result as string;
        localStorage.setItem(`user_image_${userId}`, base64String);
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(resizedBlob);
    });
  } catch (error) {
    console.error('Error saving image:', error);
    throw error;
  }
};

export const getImageFromLocalStorage = (userId: string): string | null => {
  return localStorage.getItem(`user_image_${userId}`);
};

export const removeImageFromLocalStorage = (userId: string): void => {
  localStorage.removeItem(`user_image_${userId}`);
};