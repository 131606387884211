import React from 'react';
import { Phone } from 'lucide-react';
import { Country, countries } from '../data/countries';

interface PhoneInputProps {
  id: string;
  name: string;
  value: string;
  country: Country;
  onCountryChange: (country: Country) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  label?: string;
}

export default function PhoneInput({
  id,
  name,
  value,
  country,
  onCountryChange,
  onChange,
  required = false,
  label = 'Telefon Numarası'
}: PhoneInputProps) {
  return (
    <div className="space-y-2">
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="relative flex rounded-lg shadow-sm">
        <div className="relative">
          <select
            value={country.code}
            onChange={(e) => {
              const selectedCountry = countries.find(c => c.code === e.target.value);
              if (selectedCountry) onCountryChange(selectedCountry);
            }}
            className="h-full rounded-l-lg border border-r-0 border-gray-200 bg-white py-3 pl-3 pr-7 text-gray-800 focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
          >
            {countries.map((c) => (
              <option key={c.code} value={c.code}>
                {c.flag} {c.dialCode}
              </option>
            ))}
          </select>
        </div>
        <div className="relative flex-1">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Phone className="h-5 w-5 text-gray-400" />
          </div>
          <input
            id={id}
            name={name}
            type="tel"
            required={required}
            value={value}
            onChange={onChange}
            className="block w-full rounded-r-lg border border-gray-200 pl-10 pr-3 py-3 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition-shadow duration-200 ease-in-out hover:border-gray-300"
            placeholder="(5XX) XXX XX XX"
          />
        </div>
      </div>
    </div>
  );
}