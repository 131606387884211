import React, { useState } from 'react';
import { Search, MapPin, History, X } from 'lucide-react';
import { turkishCities } from '../../../data/cities';

interface CitySearchBoxProps {
  value: string;
  onChange: (value: string) => void;
}

const popularCities = [
  'İstanbul',
  'Ankara',
  'İzmir',
  'Antalya',
  'Kapadokya'
];

export default function CitySearchBox({ value, onChange }: CitySearchBoxProps) {
  const [isFocused, setIsFocused] = useState(false);
  const [recentSearches, setRecentSearches] = useState<string[]>(
    JSON.parse(localStorage.getItem('recentSearches') || '[]')
  );

  const handleSearch = (city: string) => {
    onChange(city);
    
    if (city) {
      const newSearches = [city, ...recentSearches.filter(s => s !== city)].slice(0, 5);
      setRecentSearches(newSearches);
      localStorage.setItem('recentSearches', JSON.stringify(newSearches));
    }
    
    setIsFocused(false);
  };

  const clearRecentSearches = () => {
    setRecentSearches([]);
    localStorage.removeItem('recentSearches');
  };

  return (
    <div className="relative w-full max-w-2xl mx-auto">
      <input
        type="text"
        placeholder="Şehir veya bölge ara..."
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setTimeout(() => setIsFocused(false), 200)}
        className="w-full pl-12 pr-4 py-4 bg-white/95 backdrop-blur-sm border border-gray-200 rounded-2xl focus:ring-2 focus:ring-blue-500 focus:border-transparent shadow-lg text-lg"
      />
      <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 h-6 w-6 text-gray-400" />
      
      {value && (
        <button
          onClick={() => handleSearch('')}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 p-1 hover:bg-gray-100 rounded-full"
        >
          <X className="h-5 w-5 text-gray-500" />
        </button>
      )}

      {isFocused && (
        <div className="absolute top-full left-0 right-0 mt-2 bg-white rounded-xl shadow-xl border border-gray-100 overflow-hidden z-50">
          {/* Popular Cities */}
          <div className="p-4 border-b border-gray-100">
            <h3 className="text-sm font-medium text-gray-500 mb-3">Popüler Şehirler</h3>
            <div className="flex flex-wrap gap-2">
              {popularCities.map(city => (
                <button
                  key={city}
                  onClick={() => handleSearch(city)}
                  className="px-3 py-1.5 bg-gray-50 hover:bg-gray-100 rounded-full text-sm text-gray-700 flex items-center space-x-1"
                >
                  <MapPin className="h-3.5 w-3.5" />
                  <span>{city}</span>
                </button>
              ))}
            </div>
          </div>

          {/* Recent Searches */}
          {recentSearches.length > 0 && (
            <div className="p-4 border-b border-gray-100">
              <div className="flex items-center justify-between mb-3">
                <h3 className="text-sm font-medium text-gray-500">Son Aramalar</h3>
                <button
                  onClick={clearRecentSearches}
                  className="text-xs text-gray-400 hover:text-gray-600"
                >
                  Temizle
                </button>
              </div>
              <div className="space-y-2">
                {recentSearches.map(search => (
                  <button
                    key={search}
                    onClick={() => handleSearch(search)}
                    className="w-full text-left px-3 py-2 hover:bg-gray-50 rounded-lg flex items-center space-x-3"
                  >
                    <History className="h-4 w-4 text-gray-400" />
                    <span className="text-gray-700">{search}</span>
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* All Cities */}
          <div className="max-h-64 overflow-y-auto p-2">
            {turkishCities
              .filter(city => city.toLowerCase().includes(value.toLowerCase()))
              .map(city => (
                <button
                  key={city}
                  onClick={() => handleSearch(city)}
                  className="w-full text-left px-3 py-2 hover:bg-gray-50 rounded-lg flex items-center space-x-3"
                >
                  <MapPin className="h-4 w-4 text-gray-400" />
                  <span className="text-gray-700">{city}</span>
                </button>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}