import { AES, enc } from 'crypto-js';

const ENCRYPTION_KEY = 'turla-secure-key-2024';
const MAX_LOGIN_ATTEMPTS = 5;
const LOCKOUT_DURATION = 15 * 60 * 1000; // 15 minutes

interface LoginAttempt {
  count: number;
  timestamp: number;
}

const loginAttempts: Map<string, LoginAttempt> = new Map();

export const encryptData = (data: any): string => {
  return AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
};

export const decryptData = (encryptedData: string): any => {
  try {
    const bytes = AES.decrypt(encryptedData, ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(enc.Utf8));
  } catch (error) {
    return null;
  }
};

export const checkLoginAttempts = (username: string): boolean => {
  const attempt = loginAttempts.get(username);
  const now = Date.now();

  if (!attempt) {
    loginAttempts.set(username, { count: 1, timestamp: now });
    return true;
  }

  if (now - attempt.timestamp > LOCKOUT_DURATION) {
    loginAttempts.set(username, { count: 1, timestamp: now });
    return true;
  }

  if (attempt.count >= MAX_LOGIN_ATTEMPTS) {
    return false;
  }

  attempt.count++;
  loginAttempts.set(username, attempt);
  return true;
};

export const resetLoginAttempts = (username: string): void => {
  loginAttempts.delete(username);
};

export const validatePassword = (password: string): boolean => {
  return password.length >= 6;
};

export const sanitizeInput = (input: string): string => {
  return input
    .replace(/[<>]/g, '')
    .trim();
};

export const generateSessionToken = (): string => {
  const timestamp = Date.now().toString(36);
  const random = Math.random().toString(36).substring(2, 15);
  return `token-${timestamp}-${random}`;
};

export const rateLimit = (() => {
  const attempts: Record<string, { count: number; timestamp: number }> = {};
  const MAX_ATTEMPTS = 5;
  const TIMEOUT = 15 * 60 * 1000; // 15 minutes

  return {
    checkLimit: (identifier: string): boolean => {
      const now = Date.now();
      const userAttempts = attempts[identifier];

      if (!userAttempts) {
        attempts[identifier] = { count: 1, timestamp: now };
        return true;
      }

      if (now - userAttempts.timestamp > TIMEOUT) {
        attempts[identifier] = { count: 1, timestamp: now };
        return true;
      }

      if (userAttempts.count >= MAX_ATTEMPTS) {
        return false;
      }

      attempts[identifier].count++;
      return true;
    },
    resetLimit: (identifier: string): void => {
      delete attempts[identifier];
    }
  };
})();