import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { 
  MapPin, 
  Calendar, 
  Clock, 
  Users, 
  Star, 
  Search,
  Camera,
  Info,
  DollarSign,
  Coffee,
  Utensils,
  Bus,
  Train,
  Plane,
  Hotel,
  Sun,
  Cloud,
  Umbrella
} from 'lucide-react';
import { getCityData } from '../data/cityData';

interface PlaceFilter {
  type: 'all' | 'museum' | 'historical' | 'nature' | 'photo' | 'food';
  label: string;
}

const filters: PlaceFilter[] = [
  { type: 'all', label: 'Tümü' },
  { type: 'museum', label: 'Müzeler' },
  { type: 'historical', label: 'Tarihi Yerler' },
  { type: 'nature', label: 'Doğal Güzellikler' },
  { type: 'photo', label: 'Fotoğraf Noktaları' },
  { type: 'food', label: 'Yeme & İçme' }
];

export default function CityDetails() {
  const { cityId } = useParams();
  const cityData = getCityData(cityId || '');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilter, setSelectedFilter] = useState<PlaceFilter['type']>('all');

  if (!cityData) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-800">Şehir bulunamadı</h1>
          <p className="text-gray-600 mt-2">Aradığınız şehir mevcut değil.</p>
        </div>
      </div>
    );
  }

  const filteredPlaces = cityData.popularPlaces.filter(place => {
    const matchesSearch = 
      place.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      place.description.toLowerCase().includes(searchQuery.toLowerCase());
    
    if (selectedFilter === 'all') return matchesSearch;
    
    // Add type-based filtering logic here
    return matchesSearch && place.type === selectedFilter;
  });

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative h-[60vh]">
        <img
          src={cityData.coverImage}
          alt={`${cityData.name} Manzarası`}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-40" />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center text-white">
            <h1 className="text-5xl font-bold mb-4">{cityData.name}</h1>
            <p className="text-xl max-w-2xl mx-auto">{cityData.slogan}</p>
          </div>
        </div>
      </div>

      {/* Quick Info Bar */}
      <div className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 py-4">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div className="flex items-center space-x-2">
              <Calendar className="h-5 w-5 text-blue-600" />
              <div>
                <p className="text-sm text-gray-600">En İyi Sezon</p>
                <p className="font-medium">{cityData.bestSeason}</p>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <Users className="h-5 w-5 text-blue-600" />
              <div>
                <p className="text-sm text-gray-600">Nüfus</p>
                <p className="font-medium">{cityData.population.toLocaleString()}</p>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <MapPin className="h-5 w-5 text-blue-600" />
              <div>
                <p className="text-sm text-gray-600">Bölge</p>
                <p className="font-medium">{cityData.region}</p>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <Star className="h-5 w-5 text-yellow-500" />
              <div>
                <p className="text-sm text-gray-600">Öne Çıkan</p>
                <p className="font-medium">{cityData.highlights.length} Nokta</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Left Column */}
          <div className="lg:col-span-2">
            {/* About Section */}
            <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
              <h2 className="text-2xl font-bold mb-4">Hakkında</h2>
              <p className="text-gray-600 leading-relaxed whitespace-pre-line">
                {cityData.description}
              </p>
            </div>

            {/* Places Section */}
            <div className="bg-white rounded-xl shadow-sm p-6">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 space-y-4 sm:space-y-0">
                <h2 className="text-2xl font-bold">Gezilecek Yerler</h2>
                <div className="flex items-center space-x-2">
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Yer ara..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                  </div>
                </div>
              </div>

              {/* Filters */}
              <div className="flex flex-wrap gap-2 mb-6">
                {filters.map((filter) => (
                  <button
                    key={filter.type}
                    onClick={() => setSelectedFilter(filter.type)}
                    className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                      selectedFilter === filter.type
                        ? 'bg-blue-600 text-white'
                        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                    }`}
                  >
                    {filter.label}
                  </button>
                ))}
              </div>

              {/* Places Grid */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {filteredPlaces.map((place) => (
                  <div key={place.name} className="group">
                    <div className="relative aspect-video rounded-lg overflow-hidden">
                      <img
                        src={place.image}
                        alt={place.name}
                        className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                      />
                      {place.price && (
                        <div className="absolute top-2 right-2 bg-white px-2 py-1 rounded-full text-sm font-medium text-blue-600">
                          {place.price} ₺
                        </div>
                      )}
                      {place.openingHours && (
                        <div className="absolute bottom-2 right-2 bg-white px-2 py-1 rounded-full text-sm font-medium text-green-600">
                          {place.openingHours}
                        </div>
                      )}
                    </div>
                    <h3 className="font-semibold mt-2">{place.name}</h3>
                    <p className="text-sm text-gray-600">{place.description}</p>
                    {place.openingHours && (
                      <div className="flex items-center mt-2 text-sm text-gray-500">
                        <Clock className="h-4 w-4 mr-1" />
                        <span>{place.openingHours}</span>
                      </div>
                    )}
                    {place.price && (
                      <div className="flex items-center mt-1 text-sm text-gray-500">
                        <DollarSign className="h-4 w-4 mr-1" />
                        <span>Giriş: {place.price} ₺</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="lg:col-span-1 space-y-6">
            {/* Weather Widget */}
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h3 className="text-lg font-semibold mb-4">Hava Durumu</h3>
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <Sun className="h-8 w-8 text-yellow-500 mr-3" />
                    <div>
                      <p className="font-medium">Bugün</p>
                      <p className="text-sm text-gray-500">Güneşli</p>
                    </div>
                  </div>
                  <span className="text-2xl font-semibold">24°</span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <Cloud className="h-8 w-8 text-gray-400 mr-3" />
                    <div>
                      <p className="font-medium">Yarın</p>
                      <p className="text-sm text-gray-500">Parçalı Bulutlu</p>
                    </div>
                  </div>
                  <span className="text-2xl font-semibold">22°</span>
                </div>
              </div>
            </div>

            {/* Transportation */}
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h3 className="text-lg font-semibold mb-4">Ulaşım</h3>
              <div className="space-y-4">
                <div className="flex items-center">
                  <Plane className="h-5 w-5 text-blue-500 mr-3" />
                  <div>
                    <p className="font-medium">Havalimanı</p>
                    <p className="text-sm text-gray-500">Şehir merkezine 45 dk</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <Train className="h-5 w-5 text-blue-500 mr-3" />
                  <div>
                    <p className="font-medium">Tren İstasyonu</p>
                    <p className="text-sm text-gray-500">Merkezi konumda</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <Bus className="h-5 w-5 text-blue-500 mr-3" />
                  <div>
                    <p className="font-medium">Otobüs Terminali</p>
                    <p className="text-sm text-gray-500">Şehir merkezine 20 dk</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Available Tours */}
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h3 className="text-lg font-semibold mb-4">Mevcut Turlar</h3>
              <div className="space-y-4">
                {cityData.tours.map((tour, index) => (
                  <div key={index} className="border-b border-gray-100 last:border-0 pb-4 last:pb-0">
                    <h4 className="font-medium">{tour.name}</h4>
                    <p className="text-sm text-gray-600 mb-2">{tour.duration}</p>
                    <div className="flex items-center justify-between">
                      <span className="text-blue-600 font-semibold">
                        {tour.price.toLocaleString('tr-TR', {
                          style: 'currency',
                          currency: 'TRY'
                        })}
                      </span>
                      <button className="text-sm bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors">
                        Detaylar
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Best Time to Visit */}
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h3 className="text-lg font-semibold mb-4">Ziyaret İçin En İyi Dönem</h3>
              <div className="space-y-4">
                <div className="flex items-center">
                  <Sun className="h-5 w-5 text-yellow-500 mr-3" />
                  <div>
                    <p className="font-medium">İlkbahar</p>
                    <p className="text-sm text-gray-500">Mart - Mayıs</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <Umbrella className="h-5 w-5 text-blue-500 mr-3" />
                  <div>
                    <p className="font-medium">Sonbahar</p>
                    <p className="text-sm text-gray-500">Eylül - Kasım</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}