import React from 'react';
import ProfileHeader from '../components/profile/ProfileHeader';
import ProfileForm from '../components/profile/ProfileForm';
import ProfileInfo from '../components/profile/ProfileInfo';
import { useProfile } from '../hooks/useProfile';

export default function Profile() {
  const {
    isEditing,
    setIsEditing,
    profile,
    profileImage,
    formData,
    setFormData,
    country,
    setCountry,
    successMessage,
    errorMessage,
    handleImageSelect,
    handleImageRemove,
    handleSubmit
  } = useProfile();

  if (!profile) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
          <p className="mt-4 text-gray-600">Yükleniyor...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      {successMessage && (
        <div className="mb-6 bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded">
          {successMessage}
        </div>
      )}

      {errorMessage && (
        <div className="mb-6 bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded">
          {errorMessage}
        </div>
      )}

      <div className="bg-white rounded-xl shadow-sm overflow-hidden">
        <ProfileHeader
          profileImage={profileImage}
          onImageSelect={handleImageSelect}
          onImageRemove={handleImageRemove}
          firstName={profile.firstName}
          lastName={profile.lastName}
          email={profile.email}
          isEditing={isEditing}
          onEditClick={() => setIsEditing(!isEditing)}
        />

        <div className="p-8 pt-16">
          {isEditing ? (
            <ProfileForm
              formData={formData}
              country={country}
              onCountryChange={setCountry}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                [e.target.name]: e.target.value
              }))}
              onSubmit={handleSubmit}
              onCancel={() => setIsEditing(false)}
            />
          ) : (
            <ProfileInfo
              email={profile.email}
              phone={profile.phone}
              city={profile.city}
            />
          )}
        </div>
      </div>
    </div>
  );
}