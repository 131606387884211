import React from 'react';
import { 
  Map, 
  Camera, 
  Coffee, 
  Mountain, 
  Shell, 
  Castle,
  UtensilsCrossed,
  Sunrise,
  Tent,
  Ship,
  Building2,
  Wine,
  Trees,
  Palmtree
} from 'lucide-react';

const categories = [
  {
    id: 'city-guides',
    icon: Map,
    title: 'Şehir Rehberleri',
    description: 'Her şehir için detaylı gezi planları',
    color: 'bg-blue-50 text-blue-600'
  },
  {
    id: 'historical',
    icon: Castle,
    title: 'Tarihi Yerler',
    description: 'Antik kentler ve tarihi yapılar',
    color: 'bg-amber-50 text-amber-600'
  },
  {
    id: 'nature',
    icon: Mountain,
    title: 'Doğal Güzellikler',
    description: 'Milli parklar ve doğa harikaları',
    color: 'bg-emerald-50 text-emerald-600'
  },
  {
    id: 'beaches',
    icon: Shell,
    title: 'Plajlar',
    description: 'En güzel koylar ve plajlar',
    color: 'bg-cyan-50 text-cyan-600'
  },
  {
    id: 'photo-spots',
    icon: Camera,
    title: 'Fotoğraf Noktaları',
    description: 'Instagram\'lık manzaralar',
    color: 'bg-purple-50 text-purple-600'
  },
  {
    id: 'local-food',
    icon: UtensilsCrossed,
    title: 'Yerel Lezzetler',
    description: 'Yöresel mutfaklar ve restoranlar',
    color: 'bg-red-50 text-red-600'
  },
  {
    id: 'sunrise-spots',
    icon: Sunrise,
    title: 'Gün Doğumu Noktaları',
    description: 'En güzel gün doğumu manzaraları',
    color: 'bg-orange-50 text-orange-600'
  },
  {
    id: 'camping',
    icon: Tent,
    title: 'Kamp Alanları',
    description: 'Doğayla baş başa kamp noktaları',
    color: 'bg-lime-50 text-lime-600'
  },
  {
    id: 'boat-tours',
    icon: Ship,
    title: 'Tekne Turları',
    description: 'Mavi yolculuk ve tekne rotaları',
    color: 'bg-indigo-50 text-indigo-600'
  },
  {
    id: 'museums',
    icon: Building2,
    title: 'Müzeler',
    description: 'Tarih ve sanat müzeleri',
    color: 'bg-rose-50 text-rose-600'
  }
];

export default function GuideCategories() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Rehber Kategorileri
          </h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            İlgi alanınıza göre detaylı rehberler ve öneriler
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6">
          {categories.map((category) => (
            <div
              key={category.id}
              className={`${category.color} p-6 rounded-xl shadow-sm hover:shadow-md transition-all duration-300`}
            >
              <div className="flex items-center">
                <div className="w-12 h-12 rounded-xl flex items-center justify-center bg-white/50">
                  <category.icon className="h-6 w-6" />
                </div>
                <div className="ml-4">
                  <h3 className="text-lg font-semibold">{category.title}</h3>
                  <p className="text-sm opacity-75">{category.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}