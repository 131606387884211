import { encryptData, decryptData } from './security';
import { User } from '../types/user';

const SESSION_KEY = 'user_session';
const SESSION_DURATION = 24 * 60 * 60 * 1000; // 24 hours

interface Session {
  user: User;
  token: string;
  expiresAt: number;
}

export const createSession = (user: User): void => {
  const session: Session = {
    user,
    token: generateSessionToken(),
    expiresAt: Date.now() + SESSION_DURATION
  };

  localStorage.setItem('currentUser', JSON.stringify(user));
  localStorage.setItem(SESSION_KEY, encryptData(session));
};

export const getSession = (): Session | null => {
  const sessionData = localStorage.getItem(SESSION_KEY);
  if (!sessionData) return null;

  const session = decryptData(sessionData);
  if (!session) return null;

  if (Date.now() > session.expiresAt) {
    destroySession();
    return null;
  }

  return session;
};

export const updateSession = (updates: Partial<User>): void => {
  const session = getSession();
  if (!session) return;

  const updatedUser = { ...session.user, ...updates };
  const updatedSession: Session = {
    ...session,
    user: updatedUser
  };

  localStorage.setItem('currentUser', JSON.stringify(updatedUser));
  localStorage.setItem(SESSION_KEY, encryptData(updatedSession));
};

export const destroySession = (): void => {
  localStorage.removeItem(SESSION_KEY);
  localStorage.removeItem('currentUser');
};

export const isAuthenticated = (): boolean => {
  return getSession() !== null;
};

export const refreshSession = (): void => {
  const session = getSession();
  if (!session) return;

  const refreshedSession: Session = {
    ...session,
    expiresAt: Date.now() + SESSION_DURATION
  };

  localStorage.setItem(SESSION_KEY, encryptData(refreshedSession));
};

const generateSessionToken = (): string => {
  const timestamp = Date.now().toString(36);
  const random = Math.random().toString(36).substring(2, 15);
  return `session-${timestamp}-${random}`;
};