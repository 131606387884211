import { User, UserRole } from '../types/user';
import { validateEmail, validatePhone } from '../utils/validation';

const USER_SESSION_KEY = 'user_session';
const USERS_STORAGE_KEY = 'users';
const ADMIN_CREDENTIALS = {
  username: '31042879262',
  password: 'Caner1605.'
};

// Helper function to get users from localStorage
const getStoredUsers = (): User[] => {
  try {
    const usersStr = localStorage.getItem(USERS_STORAGE_KEY);
    return usersStr ? JSON.parse(usersStr) : [];
  } catch (error) {
    console.error('Error getting users:', error);
    return [];
  }
};

// Helper function to save users to localStorage
const saveUsers = (users: User[]): void => {
  try {
    localStorage.setItem(USERS_STORAGE_KEY, JSON.stringify(users));
  } catch (error) {
    console.error('Error saving users:', error);
  }
};

export const register = async (userData: any) => {
  try {
    // Email format validation
    if (!validateEmail(userData.email)) {
      return { 
        success: false, 
        error: 'Geçerli bir e-posta adresi giriniz' 
      };
    }

    // Phone format validation
    if (!validatePhone(userData.phone)) {
      return { 
        success: false, 
        error: 'Geçerli bir telefon numarası giriniz' 
      };
    }

    // Get existing users
    const existingUsers = getStoredUsers();

    // Check for duplicate email
    if (existingUsers.some(user => user.email.toLowerCase() === userData.email.toLowerCase())) {
      return { 
        success: false, 
        error: 'Bu e-posta adresi zaten kullanımda' 
      };
    }

    // Create new user
    const newUser: User = {
      id: `user-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      phone: userData.phone,
      city: userData.city,
      role: UserRole.USER,
      status: 'active',
      joinDate: new Date().toISOString(),
      lastLogin: new Date().toISOString()
    };

    // Save user
    const updatedUsers = [...existingUsers, newUser];
    saveUsers(updatedUsers);

    // Create session
    localStorage.setItem('currentUser', JSON.stringify(newUser));

    return { success: true, user: newUser };
  } catch (error) {
    console.error('Registration error:', error);
    return { 
      success: false, 
      error: 'Kayıt işlemi sırasında bir hata oluştu' 
    };
  }
};

export const login = async (email: string, password: string) => {
  try {
    // Admin login check
    if (email === '31042879262' && password === 'Caner1605.') {
      const adminUser = {
        id: 'admin',
        firstName: 'Admin',
        lastName: 'User',
        email,
        phone: '+905555555555',
        city: 'İstanbul',
        role: UserRole.ADMIN,
        status: 'active',
        joinDate: new Date().toISOString(),
        lastLogin: new Date().toISOString()
      };
      
      localStorage.setItem('currentUser', JSON.stringify(adminUser));
      localStorage.setItem('adminAuth', 'true');
      return { success: true, user: adminUser };
    }

    // Regular user login
    const users = getStoredUsers();
    const user = users.find(u => u.email.toLowerCase() === email.toLowerCase());

    if (!user) {
      return { 
        success: false, 
        error: 'Geçersiz e-posta veya şifre' 
      };
    }

    // Update last login
    user.lastLogin = new Date().toISOString();
    saveUsers(users.map(u => u.id === user.id ? user : u));

    localStorage.setItem('currentUser', JSON.stringify(user));
    return { success: true, user };
  } catch (error) {
    console.error('Login error:', error);
    return { 
      success: false, 
      error: 'Giriş işlemi sırasında bir hata oluştu' 
    };
  }
};

export const validateAdminCredentials = (username: string, password: string): boolean => {
  const isValid = username === ADMIN_CREDENTIALS.username && password === ADMIN_CREDENTIALS.password;
  if (isValid) {
    localStorage.setItem('adminAuth', 'true');
  }
  return isValid;
};

export const logout = () => {
  localStorage.removeItem('currentUser');
  localStorage.removeItem('adminAuth');
  localStorage.removeItem(USER_SESSION_KEY);
};

export const getCurrentUser = () => {
  const userStr = localStorage.getItem('currentUser');
  return userStr ? JSON.parse(userStr) : null;
};

export const isAuthenticated = () => {
  return !!getCurrentUser();
};

export const isAdmin = () => {
  const user = getCurrentUser();
  return user?.role === UserRole.ADMIN;
};