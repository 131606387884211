import React from 'react';
import { Mail, Phone, MapPin } from 'lucide-react';

interface ProfileInfoProps {
  email: string;
  phone: string;
  city: string;
}

export default function ProfileInfo({ email, phone, city }: ProfileInfoProps) {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <h3 className="text-sm font-medium text-gray-500">İletişim Bilgileri</h3>
          <div className="mt-3 space-y-3">
            <div className="flex items-center text-gray-700">
              <Mail className="h-5 w-5 mr-2 text-gray-400" />
              {email}
            </div>
            <div className="flex items-center text-gray-700">
              <Phone className="h-5 w-5 mr-2 text-gray-400" />
              {phone}
            </div>
            <div className="flex items-center text-gray-700">
              <MapPin className="h-5 w-5 mr-2 text-gray-400" />
              {city}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}