import React, { useState } from 'react';
import { Grid, List, MapPin, Filter, ArrowUpDown } from 'lucide-react';
import CityCard from './CityCard';
import CityListItem from './CityListItem';
import GridControls from './GridControls';
import EmptyState from './EmptyState';
import { CityData } from '../../../types';

interface CitiesGridProps {
  cities: CityData[];
}

type SortOption = 'name' | 'popularity' | 'region';
type ViewMode = 'grid' | 'list';

export default function CitiesGrid({ cities }: CitiesGridProps) {
  const [viewMode, setViewMode] = useState<ViewMode>('grid');
  const [sortBy, setSortBy] = useState<SortOption>('name');
  const [selectedRegion, setSelectedRegion] = useState<string>('all');

  const regions = Array.from(new Set(cities.map(city => city.region)));

  const sortedCities = [...cities].sort((a, b) => {
    switch (sortBy) {
      case 'name':
        return a.name.localeCompare(b.name);
      case 'popularity':
        return (b.attractions.historical.length + b.attractions.natural.length) - 
               (a.attractions.historical.length + a.attractions.natural.length);
      case 'region':
        return a.region.localeCompare(b.region);
      default:
        return 0;
    }
  });

  const filteredCities = selectedRegion === 'all' 
    ? sortedCities 
    : sortedCities.filter(city => city.region === selectedRegion);

  if (cities.length === 0) {
    return <EmptyState />;
  }

  return (
    <div className="space-y-6">
      <GridControls
        viewMode={viewMode}
        setViewMode={setViewMode}
        sortBy={sortBy}
        setSortBy={setSortBy}
        selectedRegion={selectedRegion}
        setSelectedRegion={setSelectedRegion}
        regions={regions}
      />

      {viewMode === 'grid' ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {filteredCities.map((city) => (
            <CityCard key={city.id} city={city} />
          ))}
        </div>
      ) : (
        <div className="space-y-4">
          {filteredCities.map((city) => (
            <CityListItem key={city.id} city={city} />
          ))}
        </div>
      )}

      <div className="text-center text-sm text-gray-500">
        Toplam {filteredCities.length} şehir gösteriliyor
      </div>
    </div>
  );
}