import React from 'react';
import { Mountain, Coffee, Music, Camera, Sunrise, Heart } from 'lucide-react';

const categories = [
  { icon: Mountain, name: 'Macera', count: '120+ aktivite' },
  { icon: Coffee, name: 'Gastronomi', count: '85+ aktivite' },
  { icon: Music, name: 'Kültür & Sanat', count: '95+ aktivite' },
  { icon: Camera, name: 'Fotoğraf Turları', count: '75+ aktivite' },
  { icon: Sunrise, name: 'Doğa', count: '110+ aktivite' },
  { icon: Heart, name: 'Yerel Yaşam', count: '65+ aktivite' }
];

export default function ExperienceCategories() {
  return (
    <div className="container mx-auto px-4 mb-16">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
        {categories.map((category, index) => (
          <button
            key={index}
            className="p-4 bg-white rounded-xl shadow-sm hover:shadow-md transition-all text-center"
          >
            <category.icon className="h-8 w-8 text-blue-600 mx-auto mb-3" />
            <h3 className="font-medium text-gray-900 mb-1">{category.name}</h3>
            <p className="text-sm text-gray-500">{category.count}</p>
          </button>
        ))}
      </div>
    </div>
  );
}