import React from 'react';

interface ViewToggleProps {
  activeView: 'list' | 'map';
  onViewChange: (view: 'list' | 'map') => void;
}

export default function ViewToggle({ activeView, onViewChange }: ViewToggleProps) {
  return (
    <div className="flex bg-white rounded-lg shadow-sm p-1">
      <button
        onClick={() => onViewChange('list')}
        className={`px-4 py-2 rounded-lg transition-colors ${
          activeView === 'list'
            ? 'bg-blue-600 text-white'
            : 'text-gray-600 hover:text-blue-600'
        }`}
      >
        Liste
      </button>
      <button
        onClick={() => onViewChange('map')}
        className={`px-4 py-2 rounded-lg transition-colors ${
          activeView === 'map'
            ? 'bg-blue-600 text-white'
            : 'text-gray-600 hover:text-blue-600'
        }`}
      >
        Harita
      </button>
    </div>
  );
}