import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { validateAdminCredentials } from '../services/auth';

interface AdminAuthMiddlewareProps {
  children: React.ReactNode;
}

export default function AdminAuthMiddleware({ children }: AdminAuthMiddlewareProps) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAuth = () => {
      const isAdminRoute = location.pathname.startsWith('/admincaner01');
      const isLoginRoute = location.pathname === '/admincaner01/login';

      if (isAdminRoute && !isLoginRoute) {
        const adminAuth = localStorage.getItem('adminAuth');
        if (adminAuth !== 'true') {
          navigate('/admincaner01/login', {
            state: { 
              returnUrl: location.pathname,
              message: 'Yönetici paneline erişmek için giriş yapmanız gerekmektedir.'
            }
          });
        }
      }
    };

    checkAuth();
  }, [navigate, location]);

  return <>{children}</>;
}