export interface Country {
  code: string;
  name: string;
  dialCode: string;
  flag: string;
}

export const countries: Country[] = [
  { code: 'TR', name: 'Türkiye', dialCode: '+90', flag: '🇹🇷' },
  { code: 'DE', name: 'Almanya', dialCode: '+49', flag: '🇩🇪' },
  { code: 'US', name: 'Amerika Birleşik Devletleri', dialCode: '+1', flag: '🇺🇸' },
  { code: 'GB', name: 'Birleşik Krallık', dialCode: '+44', flag: '🇬🇧' },
  { code: 'FR', name: 'Fransa', dialCode: '+33', flag: '🇫🇷' },
  { code: 'NL', name: 'Hollanda', dialCode: '+31', flag: '🇳🇱' },
  { code: 'BE', name: 'Belçika', dialCode: '+32', flag: '🇧🇪' },
  { code: 'AT', name: 'Avusturya', dialCode: '+43', flag: '🇦🇹' },
  { code: 'CH', name: 'İsviçre', dialCode: '+41', flag: '🇨🇭' },
  { code: 'SE', name: 'İsveç', dialCode: '+46', flag: '🇸🇪' }
];