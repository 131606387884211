import React from 'react';
import { Shield, Users, Award, Globe, Phone, Mail, MapPin } from 'lucide-react';

export default function About() {
  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      {/* Hero Section */}
      <div className="bg-blue-600 text-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold mb-4">Hakkımızda</h1>
          <p className="text-xl text-blue-100">
            2020'den beri Türkiye'nin güzelliklerini dünyaya tanıtıyoruz
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Mission & Vision */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-8">Misyon & Vizyon</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-white p-8 rounded-xl shadow-sm">
              <h3 className="text-xl font-bold text-blue-600 mb-4">Misyonumuz</h3>
              <p className="text-gray-600 leading-relaxed">
                Türkiye'nin eşsiz doğal güzelliklerini, zengin tarihini ve kültürel mirasını 
                en iyi şekilde tanıtmak ve ziyaretçilerimize unutulmaz deneyimler sunmak.
              </p>
            </div>
            <div className="bg-white p-8 rounded-xl shadow-sm">
              <h3 className="text-xl font-bold text-blue-600 mb-4">Vizyonumuz</h3>
              <p className="text-gray-600 leading-relaxed">
                Türkiye'nin turizm potansiyelini en üst düzeyde değerlendirerek, 
                sürdürülebilir ve kaliteli hizmet anlayışıyla sektörün öncü markası olmak.
              </p>
            </div>
          </div>
        </div>

        {/* Values */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-8">Değerlerimiz</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-xl shadow-sm">
              <Shield className="h-8 w-8 text-blue-600 mb-4" />
              <h3 className="text-lg font-bold text-gray-900 mb-2">Güvenilirlik</h3>
              <p className="text-gray-600">
                Müşterilerimize her zaman şeffaf ve dürüst hizmet sunuyoruz.
              </p>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-sm">
              <Users className="h-8 w-8 text-blue-600 mb-4" />
              <h3 className="text-lg font-bold text-gray-900 mb-2">Müşteri Odaklılık</h3>
              <p className="text-gray-600">
                Misafirlerimizin memnuniyeti bizim önceliğimizdir.
              </p>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-sm">
              <Award className="h-8 w-8 text-blue-600 mb-4" />
              <h3 className="text-lg font-bold text-gray-900 mb-2">Kalite</h3>
              <p className="text-gray-600">
                Her hizmetimizde en yüksek kalite standartlarını hedefliyoruz.
              </p>
            </div>
          </div>
        </div>

        {/* Contact */}
        <div>
          <h2 className="text-3xl font-bold text-gray-900 mb-8">İletişim</h2>
          <div className="bg-white p-8 rounded-xl shadow-sm">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <h3 className="text-xl font-bold text-blue-600 mb-4">Bize Ulaşın</h3>
                <div className="space-y-4">
                  <div className="flex items-center">
                    <Phone className="h-5 w-5 text-gray-400 mr-3" />
                    <span className="text-gray-600">+90 (212) 555 0123</span>
                  </div>
                  <div className="flex items-center">
                    <Mail className="h-5 w-5 text-gray-400 mr-3" />
                    <span className="text-gray-600">info@turla.com</span>
                  </div>
                  <div className="flex items-center">
                    <MapPin className="h-5 w-5 text-gray-400 mr-3" />
                    <span className="text-gray-600">
                      Levent Mah. Büyükdere Cad. No:201<br />
                      Şişli, İstanbul
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <h3 className="text-xl font-bold text-blue-600 mb-4">Çalışma Saatleri</h3>
                <div className="space-y-2 text-gray-600">
                  <p>Pazartesi - Cuma: 09:00 - 18:00</p>
                  <p>Cumartesi: 10:00 - 14:00</p>
                  <p>Pazar: Kapalı</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}