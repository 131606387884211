import React from 'react';
import { MapPin, Users, Clock, Star, Heart, Share2, Calendar, Tag } from 'lucide-react';

const experiences = [
  {
    id: 1,
    title: 'Kapadokya Balon Turu',
    image: 'https://images.unsplash.com/photo-1641128324972-af3212f0f6bd',
    location: 'Nevşehir, Kapadokya',
    duration: '3 saat',
    groupSize: '16-20 kişi',
    rating: 4.9,
    reviews: 856,
    price: 3500,
    category: 'Macera',
    nextAvailable: '25 Nisan',
    features: ['Profesyonel pilot', 'Kahvaltı', 'Transfer', 'Sigorta'],
    difficulty: 'Kolay'
  },
  {
    id: 2,
    title: 'İstanbul Lezzet Turu',
    image: 'https://images.unsplash.com/photo-1662376569736-92b2587e9cdd',
    location: 'İstanbul, Kadıköy',
    duration: '4 saat',
    groupSize: '6-10 kişi',
    rating: 4.8,
    reviews: 432,
    price: 800,
    category: 'Gastronomi',
    nextAvailable: '22 Nisan',
    features: ['7 farklı durak', 'Yerel rehber', 'Tüm tadımlar', 'Özel tarif'],
    difficulty: 'Kolay'
  },
  {
    id: 3,
    title: 'Efes Antik Kenti Fotoğraf Turu',
    image: 'https://images.unsplash.com/photo-1590595978583-3967cf17d2ea',
    location: 'İzmir, Selçuk',
    duration: '6 saat',
    groupSize: '8-12 kişi',
    rating: 4.7,
    reviews: 289,
    price: 1200,
    category: 'Fotoğraf',
    nextAvailable: '23 Nisan',
    features: ['Profesyonel fotoğrafçı', 'Ekipman desteği', 'Transfer', 'Öğle yemeği'],
    difficulty: 'Orta'
  },
  {
    id: 4,
    title: 'Pamukkale Günbatımı Turu',
    image: 'https://images.unsplash.com/photo-1590071089561-2087ff1fc868',
    location: 'Denizli, Pamukkale',
    duration: '5 saat',
    groupSize: '10-15 kişi',
    rating: 4.9,
    reviews: 367,
    price: 950,
    category: 'Doğa',
    nextAvailable: '24 Nisan',
    features: ['Antik havuz', 'Rehberlik', 'Transfer', 'Akşam yemeği'],
    difficulty: 'Kolay'
  }
];

export default function ExperienceGrid() {
  return (
    <div className="container mx-auto px-4 pb-16">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {experiences.map((experience) => (
          <div
            key={experience.id}
            className="bg-white rounded-xl shadow-sm hover:shadow-xl transition-all duration-300 overflow-hidden group"
          >
            {/* Image Section */}
            <div className="relative aspect-[4/3]">
              <img
                src={experience.image}
                alt={experience.title}
                className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-500"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent" />
              
              {/* Top Actions */}
              <div className="absolute top-4 left-4 right-4 flex justify-between items-start">
                <span className="px-3 py-1 bg-white/90 backdrop-blur-sm rounded-full text-sm font-medium text-blue-600">
                  {experience.category}
                </span>
                <div className="flex space-x-2">
                  <button className="p-2 bg-white/90 backdrop-blur-sm rounded-full hover:bg-white transition-colors">
                    <Heart className="h-4 w-4 text-red-500" />
                  </button>
                  <button className="p-2 bg-white/90 backdrop-blur-sm rounded-full hover:bg-white transition-colors">
                    <Share2 className="h-4 w-4 text-blue-500" />
                  </button>
                </div>
              </div>

              {/* Title and Location */}
              <div className="absolute bottom-4 left-4 right-4">
                <h3 className="text-xl font-bold text-white mb-2">{experience.title}</h3>
                <div className="flex items-center text-white/90">
                  <MapPin className="h-4 w-4 mr-1" />
                  <span className="text-sm">{experience.location}</span>
                </div>
              </div>
            </div>

            {/* Content Section */}
            <div className="p-6">
              {/* Info Grid */}
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div className="flex items-center text-gray-600">
                  <Clock className="h-4 w-4 mr-2 text-blue-600" />
                  <span className="text-sm">{experience.duration}</span>
                </div>
                <div className="flex items-center text-gray-600">
                  <Users className="h-4 w-4 mr-2 text-blue-600" />
                  <span className="text-sm">{experience.groupSize}</span>
                </div>
                <div className="flex items-center text-gray-600">
                  <Calendar className="h-4 w-4 mr-2 text-blue-600" />
                  <span className="text-sm">{experience.nextAvailable}</span>
                </div>
                <div className="flex items-center text-gray-600">
                  <Tag className="h-4 w-4 mr-2 text-blue-600" />
                  <span className="text-sm">{experience.difficulty}</span>
                </div>
              </div>

              {/* Features */}
              <div className="flex flex-wrap gap-2 mb-4">
                {experience.features.map((feature, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-xs"
                  >
                    {feature}
                  </span>
                ))}
              </div>

              {/* Rating and Price */}
              <div className="flex items-center justify-between pt-4 border-t border-gray-100">
                <div className="flex items-center">
                  <div className="flex items-center mr-2">
                    <Star className="h-5 w-5 text-yellow-400 fill-current" />
                    <span className="ml-1 font-medium">{experience.rating}</span>
                  </div>
                  <span className="text-sm text-gray-500">
                    ({experience.reviews} değerlendirme)
                  </span>
                </div>
                <div className="flex items-center space-x-4">
                  <div className="text-right">
                    <div className="text-2xl font-bold text-blue-600">
                      {experience.price}₺
                    </div>
                    <div className="text-xs text-gray-500">kişi başı</div>
                  </div>
                  <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors">
                    Rezervasyon
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}