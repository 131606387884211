import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  LayoutDashboard, 
  Users, 
  Map, 
  Calendar, 
  MessageSquare, 
  Settings,
  LogOut,
  Menu,
  X,
  Bell,
  User,
  ChevronDown,
  Database,
  FileText,
  HelpCircle
} from 'lucide-react';
import { clearAdminSession } from '../../services/admin';
import Header from './Header';
import QuickActions from './QuickActions';

interface AdminLayoutProps {
  children: React.ReactNode;
}

const navigation = [
  { name: 'Dashboard', href: '/admincaner01', icon: LayoutDashboard },
  { name: 'Üyeler', href: '/admincaner01/users', icon: Users },
  { name: 'Turlar', href: '/admincaner01/tours', icon: Map },
  { name: 'Rezervasyonlar', href: '/admincaner01/bookings', icon: Calendar },
  { name: 'Yorumlar', href: '/admincaner01/reviews', icon: MessageSquare },
  { name: 'Raporlar', href: '/admincaner01/reports', icon: FileText },
  { name: 'Yedekleme', href: '/admincaner01/database', icon: Database },
  { name: 'Ayarlar', href: '/admincaner01/settings', icon: Settings }
];

export default function AdminLayout({ children }: AdminLayoutProps) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleSearch = (query: string) => {
    console.log('Arama:', query);
    // Arama fonksiyonunu burada implement et
  };

  const handleLogout = () => {
    clearAdminSession();
    navigate('/admincaner01/login');
  };

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsSidebarOpen(false);
        setIsProfileMenuOpen(false);
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Mobile sidebar */}
      <div className="lg:hidden">
        {isSidebarOpen && (
          <div className="fixed inset-0 z-40">
            <div
              className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
              onClick={() => setIsSidebarOpen(false)}
            />

            <div className="fixed inset-y-0 left-0 flex w-full max-w-xs flex-col bg-white shadow-xl">
              <div className="flex items-center justify-between px-4 py-4 border-b border-gray-200">
                <Link to="/admincaner01" className="text-xl font-bold text-gray-900">
                  Turla Admin
                </Link>
                <button
                  onClick={() => setIsSidebarOpen(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>

              <nav className="flex-1 overflow-y-auto p-4">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={`flex items-center px-4 py-3 text-sm font-medium rounded-lg ${
                      location.pathname === item.href
                        ? 'bg-blue-50 text-blue-600'
                        : 'text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    <item.icon className="h-5 w-5 mr-3" />
                    {item.name}
                  </Link>
                ))}
              </nav>
            </div>
          </div>
        )}
      </div>

      {/* Desktop layout */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
        <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
          <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200">
            <Link to="/admincaner01" className="text-xl font-bold text-gray-900">
              Turla Admin
            </Link>
          </div>

          <nav className="flex-1 space-y-1 px-4 py-4">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={`flex items-center px-4 py-3 text-sm font-medium rounded-lg ${
                  location.pathname === item.href
                    ? 'bg-blue-50 text-blue-600'
                    : 'text-gray-700 hover:bg-gray-50'
                }`}
              >
                <item.icon className="h-5 w-5 mr-3" />
                {item.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>

      {/* Main content */}
      <div className="lg:pl-64 flex flex-col min-h-screen">
        <Header onSearch={handleSearch} onLogout={handleLogout} />
        
        <main className="flex-1 p-6">
          {/* Quick Actions */}
          <div className="mb-6">
            <QuickActions />
          </div>
          
          {/* Page Content */}
          {children}
        </main>
      </div>
    </div>
  );
}