import React from 'react';

const socialProviders = [
  {
    name: 'Google',
    icon: 'https://www.google.com/favicon.ico',
    bgColor: 'bg-white',
    textColor: 'text-gray-700',
    borderColor: 'border-gray-200'
  },
  {
    name: 'Apple',
    icon: 'https://www.apple.com/favicon.ico',
    bgColor: 'bg-black',
    textColor: 'text-white',
    borderColor: 'border-black'
  }
];

export default function SocialLogin() {
  return (
    <div className="mt-6">
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-white text-gray-500">Veya şununla devam et</span>
        </div>
      </div>

      <div className="mt-6 grid grid-cols-2 gap-4">
        {socialProviders.map((provider) => (
          <button
            key={provider.name}
            className={`w-full inline-flex justify-center items-center py-3 px-4 rounded-lg border ${provider.borderColor} ${provider.bgColor} hover:bg-opacity-90 transition-colors duration-200 ease-in-out shadow-sm`}
          >
            <img className="h-5 w-5" src={provider.icon} alt={provider.name} />
            <span className={`ml-2 text-sm font-medium ${provider.textColor}`}>
              {provider.name}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
}