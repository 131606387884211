import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Plane } from 'lucide-react';
import LoginForm from '../components/auth/LoginForm';
import SocialLogin from '../components/auth/SocialLogin';
import { login } from '../services/auth';
import { createSession } from '../utils/session';
import { handleError } from '../utils/errorHandling';

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleLogin = async (email: string, password: string, rememberMe: boolean) => {
    setError('');
    setIsLoading(true);

    try {
      const result = await login(email, password);

      if (result.success) {
        createSession(result.user);
        
        const returnUrl = location.state?.returnUrl || '/';
        navigate(returnUrl, {
          state: {
            loginSuccess: true,
            message: 'Başarıyla giriş yaptınız! Hoş geldiniz.'
          }
        });
      } else {
        setError(result.error || 'Giriş yapılırken bir hata oluştu.');
      }
    } catch (err) {
      const { message } = handleError(err);
      setError(message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-white to-blue-50">
      <div className="container mx-auto min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex justify-center">
            <div className="flex items-center space-x-2">
              <Plane className="h-8 w-8 text-blue-600" />
              <span className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-cyan-500 bg-clip-text text-transparent">
                Turla
              </span>
            </div>
          </div>
          
          <h2 className="mt-6 text-center text-4xl font-bold tracking-tight text-gray-900">
            Hesabınıza Giriş Yapın
          </h2>
          <p className="mt-3 text-center text-lg text-gray-600">
            Hesabınız yok mu?{' '}
            <Link 
              to="/register" 
              className="font-semibold text-blue-600 hover:text-blue-500 transition-colors"
            >
              Hemen ücretsiz kayıt olun
            </Link>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white/70 backdrop-blur-lg py-8 px-4 shadow-2xl shadow-gray-200/50 sm:rounded-2xl sm:px-10 border border-gray-100">
            <LoginForm
              onSubmit={handleLogin}
              isLoading={isLoading}
              error={error}
            />
            <SocialLogin />
          </div>

          <div className="mt-6 text-center">
            <p className="text-sm text-gray-600">
              Giriş yaparak{' '}
              <Link to="/terms" className="text-blue-600 hover:text-blue-500">
                kullanım şartlarını
              </Link>
              {' '}ve{' '}
              <Link to="/privacy" className="text-blue-600 hover:text-blue-500">
                gizlilik politikasını
              </Link>
              {' '}kabul etmiş olursunuz.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}