import React from 'react';
import { User } from 'lucide-react';

const recentUsers = [
  {
    id: 1,
    name: 'Ahmet Yılmaz',
    email: 'ahmet@example.com',
    city: 'İstanbul',
    joinDate: '2024-03-20'
  },
  {
    id: 2,
    name: 'Ayşe Demir',
    email: 'ayse@example.com',
    city: 'Ankara',
    joinDate: '2024-03-19'
  },
  {
    id: 3,
    name: 'Mehmet Kaya',
    email: 'mehmet@example.com',
    city: 'İzmir',
    joinDate: '2024-03-18'
  }
];

export default function RecentUsers() {
  return (
    <div className="flow-root">
      <ul className="-my-5 divide-y divide-gray-200">
        {recentUsers.map((user) => (
          <li key={user.id} className="py-4">
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center">
                  <User className="h-6 w-6 text-gray-500" />
                </div>
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-gray-900 truncate">
                  {user.name}
                </p>
                <p className="text-sm text-gray-500 truncate">{user.email}</p>
                <p className="text-sm text-gray-500">
                  {user.city} • Katılım: {new Date(user.joinDate).toLocaleDateString('tr-TR')}
                </p>
              </div>
              <div>
                <button className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  Detaylar
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}