import { useState, useMemo } from 'react';
import { getAllCities } from '../../../data/cities';

export function useDestinations() {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeView, setActiveView] = useState<'list' | 'map'>('list');
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [sortBy, setSortBy] = useState('name');
  const [selectedRegion, setSelectedRegion] = useState('all');
  
  const cities = getAllCities();

  const filteredCities = useMemo(() => {
    return cities.filter(city => {
      const searchLower = searchTerm.toLowerCase();
      return (
        city.name.toLowerCase().includes(searchLower) ||
        city.description.toLowerCase().includes(searchLower) ||
        city.region.toLowerCase().includes(searchLower)
      );
    });
  }, [cities, searchTerm]);

  return {
    searchTerm,
    setSearchTerm,
    activeView,
    setActiveView,
    viewMode,
    setViewMode,
    sortBy,
    setSortBy,
    selectedRegion,
    setSelectedRegion,
    filteredCities
  };
}