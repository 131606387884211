import React from 'react';
import { useDestinations } from '../features/destinations/hooks/useDestinations';
import DestinationsHeader from '../features/destinations/components/DestinationsHeader';
import DestinationsContent from '../features/destinations/components/DestinationsContent';

export default function Destinations() {
  const {
    searchTerm,
    setSearchTerm,
    activeView,
    setActiveView,
    filteredCities,
    viewMode,
    setViewMode,
    sortBy,
    setSortBy,
    selectedRegion,
    setSelectedRegion
  } = useDestinations();

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50">
      <DestinationsHeader 
        searchTerm={searchTerm}
        onSearch={setSearchTerm}
      />

      <DestinationsContent
        cities={filteredCities}
        viewMode={viewMode}
        setViewMode={setViewMode}
        sortBy={sortBy}
        setSortBy={setSortBy}
        selectedRegion={selectedRegion}
        setSelectedRegion={setSelectedRegion}
        activeView={activeView}
        setActiveView={setActiveView}
        searchTerm={searchTerm}
      />
    </div>
  );
}