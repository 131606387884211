import { User, UserRole } from '../../types/user';

// Initial admin user
const adminUser: User = {
  id: 'admin-1',
  firstName: 'Admin',
  lastName: 'User',
  email: 'admin@turla.com',
  phone: '+905555555555',
  city: 'İstanbul',
  role: UserRole.ADMIN,
  status: 'active',
  joinDate: '2024-01-01',
  lastLogin: new Date().toISOString()
};

// Initialize users in localStorage if not exists
const initializeUsers = () => {
  const existingUsers = localStorage.getItem('users');
  if (!existingUsers) {
    localStorage.setItem('users', JSON.stringify([adminUser]));
  }
};

export const getUsers = (): User[] => {
  initializeUsers();
  return JSON.parse(localStorage.getItem('users') || '[]');
};

export const getUserById = (id: string): User | undefined => {
  const users = getUsers();
  return users.find(user => user.id === id);
};

export const createUser = (user: Omit<User, 'id' | 'role'>): User => {
  const users = getUsers();
  const newUser: User = {
    ...user,
    id: `user-${Date.now()}`,
    role: UserRole.USER,
  };
  
  users.push(newUser);
  localStorage.setItem('users', JSON.stringify(users));
  return newUser;
};

export const updateUser = (id: string, updates: Partial<User>): User | null => {
  const users = getUsers();
  const index = users.findIndex(user => user.id === id);
  
  if (index === -1) return null;
  
  const updatedUser = { ...users[index], ...updates };
  users[index] = updatedUser;
  localStorage.setItem('users', JSON.stringify(users));
  
  return updatedUser;
};

export const deleteUser = (id: string): boolean => {
  const users = getUsers();
  const filteredUsers = users.filter(user => user.id !== id);
  
  if (filteredUsers.length === users.length) return false;
  
  localStorage.setItem('users', JSON.stringify(filteredUsers));
  return true;
};

export const getUserStats = () => {
  const users = getUsers();
  
  return {
    total: users.length,
    active: users.filter(user => user.status === 'active').length,
    inactive: users.filter(user => user.status === 'inactive').length,
    blocked: users.filter(user => user.status === 'blocked').length,
    newThisMonth: users.filter(user => {
      const joinDate = new Date(user.joinDate);
      const now = new Date();
      return joinDate.getMonth() === now.getMonth() && 
             joinDate.getFullYear() === now.getFullYear();
    }).length
  };
};