import React from 'react';
import ResultsHeader from './ResultsHeader';
import CitiesGrid from './CitiesGrid';
import TurkeyMap from '../../../components/TurkeyMap';
import { CityData } from '../../../types';

interface DestinationsContentProps {
  cities: CityData[];
  viewMode: 'grid' | 'list';
  setViewMode: (mode: 'grid' | 'list') => void;
  sortBy: string;
  setSortBy: (sort: string) => void;
  selectedRegion: string;
  setSelectedRegion: (region: string) => void;
  activeView: 'list' | 'map';
  setActiveView: (view: 'list' | 'map') => void;
  searchTerm: string;
}

export default function DestinationsContent({
  cities,
  viewMode,
  setViewMode,
  sortBy,
  setSortBy,
  selectedRegion,
  setSelectedRegion,
  activeView,
  setActiveView,
  searchTerm
}: DestinationsContentProps) {
  return (
    <div className="container mx-auto px-4 py-8">
      <ResultsHeader
        searchTerm={searchTerm}
        totalResults={cities.length}
        activeView={activeView}
        onViewChange={setActiveView}
      />

      {activeView === 'list' ? (
        <CitiesGrid
          cities={cities}
          viewMode={viewMode}
          setViewMode={setViewMode}
          sortBy={sortBy}
          setSortBy={setSortBy}
          selectedRegion={selectedRegion}
          setSelectedRegion={setSelectedRegion}
        />
      ) : (
        <div className="bg-white rounded-xl shadow-lg p-4">
          <TurkeyMap />
        </div>
      )}
    </div>
  );
}