import React from 'react';
import { 
  Map, 
  Calendar, 
  CreditCard, 
  Camera, 
  Utensils, 
  Bus,
  Phone,
  Sun
} from 'lucide-react';

const tips = [
  {
    icon: Calendar,
    title: 'En İyi Zaman',
    description: 'Her şehir için ideal ziyaret zamanları'
  },
  {
    icon: Map,
    title: 'Ulaşım',
    description: 'Şehir içi ve şehirler arası ulaşım rehberi'
  },
  {
    icon: CreditCard,
    title: 'Bütçe',
    description: 'Ekonomik gezi planlaması ve tavsiyeler'
  },
  {
    icon: Camera,
    title: 'Fotoğraf',
    description: 'En iyi fotoğraf noktaları ve ipuçları'
  },
  {
    icon: Utensils,
    title: 'Yeme-İçme',
    description: 'Yerel lezzetler ve restoran önerileri'
  },
  {
    icon: Bus,
    title: 'Turlar',
    description: 'Günübirlik ve uzun turlar hakkında bilgiler'
  },
  {
    icon: Phone,
    title: 'İletişim',
    description: 'Önemli numaralar ve iletişim bilgileri'
  },
  {
    icon: Sun,
    title: 'Hava Durumu',
    description: 'Mevsimsel hava durumu ve ne yapılır'
  }
];

export default function TravelTips() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Seyahat İpuçları
          </h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Gezinizi daha keyifli hale getirecek önemli bilgiler
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {tips.map((tip) => (
            <div
              key={tip.title}
              className="p-6 bg-gray-50 rounded-xl hover:shadow-md transition-all duration-300"
            >
              <div className="w-12 h-12 bg-blue-100 rounded-xl flex items-center justify-center mb-4">
                <tip.icon className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                {tip.title}
              </h3>
              <p className="text-gray-600">{tip.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}