import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { isAuthenticated, getCurrentUser } from '../services/auth';

interface AuthMiddlewareProps {
  children: React.ReactNode;
}

export default function AuthMiddleware({ children }: AuthMiddlewareProps) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAuth = () => {
      const currentUser = getCurrentUser();
      const isProtectedRoute = location.pathname.startsWith('/profile');
      const isAuthRoute = location.pathname === '/login' || location.pathname === '/register';

      if (isProtectedRoute && !currentUser) {
        navigate('/login', {
          state: { 
            returnUrl: location.pathname,
            message: 'Bu sayfayı görüntülemek için giriş yapmanız gerekmektedir.' 
          }
        });
        return;
      }

      if (isAuthRoute && currentUser) {
        navigate('/');
        return;
      }
    };

    checkAuth();
  }, [navigate, location]);

  return <>{children}</>;
}