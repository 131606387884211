export class AppError extends Error {
  constructor(
    public message: string,
    public code: string,
    public status: number = 400
  ) {
    super(message);
    this.name = 'AppError';
  }
}

export const errorCodes = {
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  UNAUTHORIZED: 'UNAUTHORIZED',
  FORBIDDEN: 'FORBIDDEN',
  RATE_LIMIT_EXCEEDED: 'RATE_LIMIT_EXCEEDED',
  SESSION_EXPIRED: 'SESSION_EXPIRED',
  INTERNAL_ERROR: 'INTERNAL_ERROR'
} as const;

export const handleError = (error: unknown): { message: string; code: string } => {
  if (error instanceof AppError) {
    return {
      message: error.message,
      code: error.code
    };
  }

  console.error('Unexpected error:', error);
  return {
    message: 'Beklenmeyen bir hata oluştu. Lütfen daha sonra tekrar deneyin.',
    code: errorCodes.INTERNAL_ERROR
  };
};

export const throwError = (code: keyof typeof errorCodes, message?: string): never => {
  const errorMessages: Record<keyof typeof errorCodes, string> = {
    INVALID_CREDENTIALS: 'Geçersiz e-posta veya şifre',
    USER_NOT_FOUND: 'Kullanıcı bulunamadı',
    VALIDATION_ERROR: 'Geçersiz form verileri',
    UNAUTHORIZED: 'Bu işlem için giriş yapmanız gerekmektedir',
    FORBIDDEN: 'Bu işlem için yetkiniz bulunmamaktadır',
    RATE_LIMIT_EXCEEDED: 'Çok fazla deneme yaptınız. Lütfen daha sonra tekrar deneyin.',
    SESSION_EXPIRED: 'Oturumunuz sona erdi. Lütfen tekrar giriş yapın.',
    INTERNAL_ERROR: 'Sistemde bir hata oluştu. Lütfen daha sonra tekrar deneyin.'
  };

  throw new AppError(
    message || errorMessages[code],
    errorCodes[code]
  );
};