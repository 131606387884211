import React from 'react';
import { Link } from 'react-router-dom';
import { MapPin, Star, Calendar } from 'lucide-react';

const destinations = [
  {
    id: 'istanbul',
    name: 'İstanbul',
    image: 'https://images.unsplash.com/photo-1524231757912-21f4fe3a7200',
    description: 'İki kıtayı birleştiren tarihi şehir',
    rating: 4.9,
    reviews: 1250,
    bestSeason: 'İlkbahar, Sonbahar',
    highlights: ['Ayasofya', 'Topkapı Sarayı', 'Boğaz Turu']
  },
  {
    id: 'cappadocia',
    name: 'Kapadokya',
    image: 'https://images.unsplash.com/photo-1641128324972-af3212f0f6bd',
    description: 'Peri bacaları ve sıcak hava balonları',
    rating: 4.8,
    reviews: 980,
    bestSeason: 'Nisan-Ekim',
    highlights: ['Balon Turu', 'Göreme', 'Yeraltı Şehirleri']
  },
  {
    id: 'antalya',
    name: 'Antalya',
    image: 'https://images.unsplash.com/photo-1542051841857-5f90071e7989',
    description: 'Turkuaz sahiller ve antik kentler',
    rating: 4.7,
    reviews: 850,
    bestSeason: 'Yaz, İlkbahar',
    highlights: ['Kaleiçi', 'Düden Şelalesi', 'Aspendos']
  }
];

export default function PopularDestinations() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Popüler Destinasyonlar
          </h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            En çok ziyaret edilen ve beğenilen rotalar
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {destinations.map((destination) => (
            <Link
              key={destination.id}
              to={`/destination/${destination.id}`}
              className="group bg-white rounded-xl shadow-sm hover:shadow-xl transition-all duration-300 overflow-hidden"
            >
              <div className="relative aspect-[4/3]">
                <img
                  src={destination.image}
                  alt={destination.name}
                  className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-500"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent" />
                
                <div className="absolute bottom-4 left-4 right-4">
                  <h3 className="text-2xl font-bold text-white mb-2">
                    {destination.name}
                  </h3>
                  <p className="text-white/90">{destination.description}</p>
                </div>
              </div>

              <div className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center">
                    <Star className="h-5 w-5 text-yellow-400 fill-current" />
                    <span className="ml-2 text-gray-700">{destination.rating}</span>
                    <span className="ml-1 text-gray-500">
                      ({destination.reviews} değerlendirme)
                    </span>
                  </div>
                  <div className="flex items-center text-gray-500">
                    <Calendar className="h-5 w-5 mr-1" />
                    <span>{destination.bestSeason}</span>
                  </div>
                </div>

                <div className="flex flex-wrap gap-2">
                  {destination.highlights.map((highlight, index) => (
                    <span
                      key={index}
                      className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm"
                    >
                      {highlight}
                    </span>
                  ))}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}