import React from 'react';
import { Star, ThumbsUp, Flag } from 'lucide-react';

const reviews = [
  {
    id: 1,
    user: 'Ahmet Y.',
    tour: 'Kapadokya Turu',
    rating: 5,
    comment: 'Muhteşem bir deneyimdi! Rehberimiz çok bilgiliydi ve program çok iyi planlanmıştı.',
    date: '2024-03-20'
  },
  {
    id: 2,
    user: 'Ayşe D.',
    tour: 'İstanbul Boğaz Turu',
    rating: 4,
    comment: 'Güzel bir gezi oldu, tekneye biniş biraz karışıktı ama genel olarak memnun kaldık.',
    date: '2024-03-19'
  },
  {
    id: 3,
    user: 'Mehmet K.',
    tour: 'Pamukkale Turu',
    rating: 5,
    comment: 'Travertenleri gezmek harikaydı! Antik havuzda yüzmek için extra ücret gerekiyor, bunu baştan belirtebilirlerdi.',
    date: '2024-03-18'
  }
];

export default function RecentReviews() {
  return (
    <div className="space-y-4">
      {reviews.map((review) => (
        <div key={review.id} className="p-4 bg-gray-50 rounded-lg">
          <div className="flex justify-between items-start">
            <div>
              <div className="font-medium text-gray-900">{review.user}</div>
              <div className="text-sm text-gray-500">{review.tour}</div>
            </div>
            <div className="flex items-center space-x-2">
              <div className="flex">
                {[...Array(5)].map((_, i) => (
                  <Star
                    key={i}
                    className={`h-4 w-4 ${
                      i < review.rating ? 'text-yellow-400 fill-current' : 'text-gray-300'
                    }`}
                  />
                ))}
              </div>
              <span className="text-sm text-gray-500">
                {new Date(review.date).toLocaleDateString('tr-TR')}
              </span>
            </div>
          </div>
          <p className="mt-2 text-sm text-gray-600">{review.comment}</p>
          <div className="mt-3 flex items-center space-x-4">
            <button className="flex items-center text-sm text-gray-500 hover:text-gray-700">
              <ThumbsUp className="h-4 w-4 mr-1" />
              Yararlı
            </button>
            <button className="flex items-center text-sm text-gray-500 hover:text-gray-700">
              <Flag className="h-4 w-4 mr-1" />
              Bildir
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}