export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validatePhone = (phone: string): boolean => {
  // Turkish phone number format: +90XXXXXXXXXX
  const phoneRegex = /^\+90[0-9]{10}$/;
  return phoneRegex.test(phone);
};

export const validatePassword = (password: string): boolean => {
  // Minimum 6 characters
  return password.length >= 6;
};

export const validateName = (name: string): boolean => {
  return name.length >= 2 && /^[a-zA-ZğüşıöçĞÜŞİÖÇ\s]+$/.test(name);
};

export const validateCity = (city: string): boolean => {
  return city.length >= 2;
};

export const getValidationErrors = (formData: any): string[] => {
  const errors: string[] = [];

  if (!validateName(formData.firstName)) {
    errors.push('Geçerli bir ad giriniz');
  }

  if (!validateName(formData.lastName)) {
    errors.push('Geçerli bir soyad giriniz');
  }

  if (!validateEmail(formData.email)) {
    errors.push('Geçerli bir e-posta adresi giriniz');
  }

  if (!validatePhone(formData.phone)) {
    errors.push('Geçerli bir telefon numarası giriniz');
  }

  if (!validateCity(formData.city)) {
    errors.push('Şehir seçimi zorunludur');
  }

  if (!validatePassword(formData.password)) {
    errors.push('Şifre en az 6 karakter olmalıdır');
  }

  return errors;
};