export enum UserRole {
  ADMIN = 'admin',
  USER = 'user'
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  city: string;
  role: UserRole;
  status: 'active' | 'inactive' | 'blocked';
  joinDate: string;
  lastLogin: string;
}

export interface UserStats {
  total: number;
  active: number;
  inactive: number;
  blocked: number;
  newThisMonth: number;
}