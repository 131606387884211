import React from 'react';
import { Bell, User, Calendar, Star, AlertCircle } from 'lucide-react';

const notifications = [
  {
    id: 1,
    type: 'booking',
    message: 'Yeni rezervasyon: Kapadokya Turu',
    time: '5 dakika önce',
    icon: Calendar,
    color: 'text-blue-500 bg-blue-100'
  },
  {
    id: 2,
    type: 'review',
    message: 'Yeni yorum: İstanbul Boğaz Turu',
    time: '15 dakika önce',
    icon: Star,
    color: 'text-yellow-500 bg-yellow-100'
  },
  {
    id: 3,
    type: 'user',
    message: 'Yeni üye kaydı: Mehmet K.',
    time: '1 saat önce',
    icon: User,
    color: 'text-green-500 bg-green-100'
  },
  {
    id: 4,
    type: 'alert',
    message: 'Sistem güncellemesi planlandı',
    time: '2 saat önce',
    icon: AlertCircle,
    color: 'text-red-500 bg-red-100'
  }
];

export default function NotificationCenter() {
  return (
    <div className="space-y-4">
      {notifications.map((notification) => (
        <div
          key={notification.id}
          className="flex items-start space-x-3 p-3 hover:bg-gray-50 rounded-lg transition-colors cursor-pointer"
        >
          <div className={`p-2 rounded-full ${notification.color}`}>
            <notification.icon className="h-5 w-5" />
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray-900">{notification.message}</p>
            <p className="text-xs text-gray-500 mt-0.5">{notification.time}</p>
          </div>
        </div>
      ))}
      <button className="w-full text-center text-sm text-blue-600 hover:text-blue-700 font-medium mt-4">
        Tüm Bildirimleri Gör
      </button>
    </div>
  );
}