import { useState, useEffect, useCallback } from 'react';
import { User } from '../types/user';
import { getUsers, updateUser, deleteUser } from '../data/database/users';

export function useUsers() {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const loadUsers = useCallback(async () => {
    try {
      setIsLoading(true);
      const allUsers = getUsers();
      setUsers(allUsers);
      setError(null);
    } catch (err) {
      setError('Kullanıcılar yüklenirken bir hata oluştu.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleUpdateUser = useCallback(async (userId: string, updates: Partial<User>) => {
    try {
      const result = updateUser(userId, updates);
      if (result) {
        await loadUsers();
        return true;
      }
      return false;
    } catch (err) {
      setError('Kullanıcı güncellenirken bir hata oluştu.');
      return false;
    }
  }, [loadUsers]);

  const handleDeleteUser = useCallback(async (userId: string) => {
    try {
      const success = deleteUser(userId);
      if (success) {
        await loadUsers();
        return true;
      }
      return false;
    } catch (err) {
      setError('Kullanıcı silinirken bir hata oluştu.');
      return false;
    }
  }, [loadUsers]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  return {
    users,
    isLoading,
    error,
    loadUsers,
    updateUser: handleUpdateUser,
    deleteUser: handleDeleteUser
  };
}