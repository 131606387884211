import React from 'react';
import { Camera, Users, Star } from 'lucide-react';

export default function ExperienceHero() {
  return (
    <div className="relative min-h-[60vh] overflow-hidden mb-16">
      <div 
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: 'url(https://images.unsplash.com/photo-1641128324972-af3212f0f6bd)',
          backgroundAttachment: 'fixed'
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black/70" />
      </div>
      
      <div className="relative container mx-auto px-4 h-full flex flex-col justify-center py-20">
        <div className="max-w-3xl">
          <div className="inline-flex items-center space-x-2 px-4 py-2 bg-white/10 backdrop-blur-sm rounded-full mb-6">
            <Star className="h-5 w-5 text-yellow-400" />
            <span className="text-white/90">Türkiye'nin En İyi Deneyimleri</span>
          </div>

          <h1 className="text-5xl md:text-6xl font-bold text-white mb-6">
            Benzersiz Deneyimler<br />Keşfedin
          </h1>
          
          <p className="text-xl text-white/90 mb-12 max-w-2xl">
            Türkiye'nin dört bir yanında yerel kültürü keşfedin, unutulmaz anılar biriktirin
          </p>
          
          <div className="grid grid-cols-3 gap-6">
            <div className="flex flex-col items-center bg-white/10 backdrop-blur-sm rounded-xl p-4">
              <Camera className="h-6 w-6 text-white mb-2" />
              <span className="text-lg font-bold text-white">500+</span>
              <span className="text-sm text-white/80">Aktivite</span>
            </div>
            <div className="flex flex-col items-center bg-white/10 backdrop-blur-sm rounded-xl p-4">
              <Users className="h-6 w-6 text-white mb-2" />
              <span className="text-lg font-bold text-white">10.000+</span>
              <span className="text-sm text-white/80">Katılımcı</span>
            </div>
            <div className="flex flex-col items-center bg-white/10 backdrop-blur-sm rounded-xl p-4">
              <Star className="h-6 w-6 text-white mb-2" />
              <span className="text-lg font-bold text-white">4.8/5</span>
              <span className="text-sm text-white/80">Ortalama Puan</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}