import React from 'react';
import { Bell, User, Settings, HelpCircle, LogOut } from 'lucide-react';
import SearchBar from './SearchBar';

interface HeaderProps {
  onSearch: (query: string) => void;
  onLogout: () => void;
}

export default function Header({ onSearch, onLogout }: HeaderProps) {
  return (
    <header className="bg-white border-b border-gray-200 px-4 py-2">
      <div className="flex items-center justify-between">
        <div className="flex-1">
          <SearchBar onSearch={onSearch} />
        </div>
        
        <div className="flex items-center space-x-4 ml-4">
          <button className="p-2 text-gray-500 hover:text-gray-700 rounded-lg hover:bg-gray-100">
            <Bell className="h-5 w-5" />
          </button>
          <button className="p-2 text-gray-500 hover:text-gray-700 rounded-lg hover:bg-gray-100">
            <Settings className="h-5 w-5" />
          </button>
          <button className="p-2 text-gray-500 hover:text-gray-700 rounded-lg hover:bg-gray-100">
            <HelpCircle className="h-5 w-5" />
          </button>
          
          <div className="border-l border-gray-200 h-6 mx-2" />
          
          <div className="flex items-center space-x-3">
            <div className="flex flex-col text-right">
              <span className="text-sm font-medium text-gray-900">Admin</span>
              <span className="text-xs text-gray-500">Yönetici</span>
            </div>
            <button
              onClick={onLogout}
              className="p-2 text-red-500 hover:text-red-700 rounded-lg hover:bg-red-50"
            >
              <LogOut className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}