import React from 'react';
import { Grid, List, Filter, ArrowUpDown } from 'lucide-react';

interface GridControlsProps {
  viewMode: 'grid' | 'list';
  setViewMode: (mode: 'grid' | 'list') => void;
  sortBy: string;
  setSortBy: (sort: any) => void;
  selectedRegion: string;
  setSelectedRegion: (region: string) => void;
  regions: string[];
}

export default function GridControls({
  viewMode,
  setViewMode,
  sortBy,
  setSortBy,
  selectedRegion,
  setSelectedRegion,
  regions
}: GridControlsProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-4 flex flex-wrap gap-4 items-center justify-between">
      <div className="flex items-center space-x-4">
        <div className="flex items-center space-x-2 text-sm">
          <Filter className="h-4 w-4 text-gray-400" />
          <select
            value={selectedRegion}
            onChange={(e) => setSelectedRegion(e.target.value)}
            className="border-none bg-transparent focus:ring-0 text-gray-600 pr-8"
          >
            <option value="all">Tüm Bölgeler</option>
            {regions.map(region => (
              <option key={region} value={region}>{region}</option>
            ))}
          </select>
        </div>

        <div className="flex items-center space-x-2 text-sm">
          <ArrowUpDown className="h-4 w-4 text-gray-400" />
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            className="border-none bg-transparent focus:ring-0 text-gray-600 pr-8"
          >
            <option value="name">İsme Göre</option>
            <option value="popularity">Popülerliğe Göre</option>
            <option value="region">Bölgeye Göre</option>
          </select>
        </div>
      </div>

      <div className="flex items-center space-x-2">
        <button
          onClick={() => setViewMode('grid')}
          className={`p-2 rounded-lg ${
            viewMode === 'grid' 
              ? 'bg-blue-50 text-blue-600' 
              : 'text-gray-400 hover:text-gray-600'
          }`}
        >
          <Grid className="h-5 w-5" />
        </button>
        <button
          onClick={() => setViewMode('list')}
          className={`p-2 rounded-lg ${
            viewMode === 'list' 
              ? 'bg-blue-50 text-blue-600' 
              : 'text-gray-400 hover:text-gray-600'
          }`}
        >
          <List className="h-5 w-5" />
        </button>
      </div>
    </div>
  );
}