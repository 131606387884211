import React, { useState, useEffect } from 'react';
import { 
  Search, 
  UserPlus, 
  Download, 
  Upload, 
  AlertCircle,
  Loader2
} from 'lucide-react';
import UserStats from '../../components/admin/UserStats';
import UserTable from '../../components/admin/UserTable';
import UserEditModal from '../../components/admin/UserEditModal';
import UserFilters from '../../components/admin/UserFilters';
import { User } from '../../types/user';
import { useUsers } from '../../hooks/useUsers';
import { filterUsers } from '../../utils/userUtils';

export default function Users() {
  const { users, isLoading, error, loadUsers, updateUser, deleteUser } = useUsers();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [stats, setStats] = useState({
    total: 0,
    active: 0,
    inactive: 0,
    blocked: 0,
    newThisMonth: 0
  });

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  useEffect(() => {
    if (users.length > 0) {
      const now = new Date();
      setStats({
        total: users.length,
        active: users.filter(user => user.status === 'active').length,
        inactive: users.filter(user => user.status === 'inactive').length,
        blocked: users.filter(user => user.status === 'blocked').length,
        newThisMonth: users.filter(user => {
          const joinDate = new Date(user.joinDate);
          return joinDate.getMonth() === now.getMonth() && 
                 joinDate.getFullYear() === now.getFullYear();
        }).length
      });
    }
  }, [users]);

  const handleStatusChange = async (userId: string, newStatus: 'active' | 'inactive' | 'blocked') => {
    if (window.confirm(`Kullanıcı durumunu "${newStatus}" olarak değiştirmek istediğinizden emin misiniz?`)) {
      const success = await updateUser(userId, { status: newStatus });
      if (success) {
        loadUsers();
      }
    }
  };

  const handleEditUser = (user: User) => {
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };

  const handleDeleteUser = async (userId: string) => {
    if (window.confirm('Bu kullanıcıyı silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.')) {
      const success = await deleteUser(userId);
      if (success) {
        loadUsers();
      }
    }
  };

  const handleSaveUser = async (updatedUser: User) => {
    const success = await updateUser(updatedUser.id, updatedUser);
    if (success) {
      loadUsers();
      setIsEditModalOpen(false);
      setSelectedUser(null);
    }
  };

  const exportUsers = () => {
    const csvContent = [
      ['ID', 'Ad', 'Soyad', 'E-posta', 'Telefon', 'Şehir', 'Durum', 'Kayıt Tarihi', 'Son Giriş'],
      ...users.map(user => [
        user.id,
        user.firstName,
        user.lastName,
        user.email,
        user.phone,
        user.city,
        user.status,
        new Date(user.joinDate).toLocaleDateString('tr-TR'),
        new Date(user.lastLogin).toLocaleDateString('tr-TR')
      ])
    ].map(row => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `users_${new Date().toISOString().split('T')[0]}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filteredUsers = filterUsers(users, searchTerm, selectedStatus);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-col items-center">
          <Loader2 className="h-8 w-8 text-blue-600 animate-spin" />
          <p className="mt-2 text-gray-600">Kullanıcılar yükleniyor...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <div className="bg-red-50 border-l-4 border-red-500 p-4 flex items-center">
          <AlertCircle className="h-6 w-6 text-red-500 mr-3" />
          <p className="text-red-700">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="mb-8">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Üye Yönetimi</h1>
            <p className="text-gray-600">Toplam {users.length} üye</p>
          </div>
          <div className="flex space-x-3">
            <button
              onClick={exportUsers}
              className="flex items-center px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
            >
              <Download className="h-5 w-5 mr-2" />
              Dışa Aktar
            </button>
            <button 
              onClick={() => handleEditUser({
                id: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                city: '',
                role: 'user',
                status: 'active',
                joinDate: new Date().toISOString(),
                lastLogin: new Date().toISOString()
              })}
              className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              <UserPlus className="h-5 w-5 mr-2" />
              Yeni Üye Ekle
            </button>
          </div>
        </div>

        <UserStats stats={stats} />
      </div>

      <UserFilters
        searchTerm={searchTerm}
        selectedStatus={selectedStatus}
        onSearchChange={setSearchTerm}
        onStatusChange={setSelectedStatus}
      />

      <UserTable
        users={filteredUsers}
        onStatusChange={handleStatusChange}
        onEdit={handleEditUser}
        onDelete={handleDeleteUser}
      />

      <UserEditModal
        user={selectedUser}
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          setSelectedUser(null);
        }}
        onSave={handleSaveUser}
      />
    </div>
  );
}