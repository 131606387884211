import React from 'react';
import { Camera, MapPin, Calendar, Mail } from 'lucide-react';
import ImageUpload from '../ImageUpload';

interface ProfileHeaderProps {
  profileImage: string | null;
  onImageSelect: (file: File) => void;
  onImageRemove: () => void;
  firstName: string;
  lastName: string;
  email: string;
  city: string;
  joinDate: string;
  isEditing: boolean;
  onEditClick: () => void;
}

export default function ProfileHeader({
  profileImage,
  onImageSelect,
  onImageRemove,
  firstName,
  lastName,
  email,
  city,
  joinDate,
  isEditing,
  onEditClick
}: ProfileHeaderProps) {
  return (
    <div className="relative">
      {/* Cover Image */}
      <div className="h-48 bg-gradient-to-r from-blue-500 to-blue-600 rounded-t-xl">
        <div className="absolute inset-0 bg-black/10 rounded-t-xl" />
      </div>

      {/* Profile Content */}
      <div className="absolute -bottom-16 left-8 flex items-end space-x-6">
        <div className="relative">
          <ImageUpload
            currentImage={profileImage}
            onImageSelect={onImageSelect}
            onImageRemove={onImageRemove}
          />
          <div className="absolute -bottom-2 -right-2 bg-white rounded-full p-1 shadow-lg">
            <Camera className="h-4 w-4 text-blue-600" />
          </div>
        </div>

        <div className="pb-4">
          <h1 className="text-2xl font-bold text-white">
            {firstName} {lastName}
          </h1>
          <div className="flex items-center space-x-4 mt-1 text-blue-100">
            <div className="flex items-center">
              <Mail className="h-4 w-4 mr-1" />
              <span>{email}</span>
            </div>
            <div className="flex items-center">
              <MapPin className="h-4 w-4 mr-1" />
              <span>{city}</span>
            </div>
            <div className="flex items-center">
              <Calendar className="h-4 w-4 mr-1" />
              <span>Üyelik: {new Date(joinDate).toLocaleDateString('tr-TR')}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="absolute bottom-4 right-8 flex space-x-3">
        <button
          onClick={onEditClick}
          className="px-4 py-2 bg-white text-blue-600 rounded-lg hover:bg-blue-50 transition-colors shadow-lg"
        >
          {isEditing ? 'İptal' : 'Profili Düzenle'}
        </button>
      </div>
    </div>
  );
}