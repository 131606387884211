import React, { useState, useEffect } from 'react';
import { Bell, Calendar, Star, AlertCircle, X, User, MapPin, MessageSquare, Gift, Clock } from 'lucide-react';

interface Notification {
  id: number;
  type: 'tour' | 'booking' | 'promo' | 'system' | 'review';
  title: string;
  message: string;
  time: string;
  read: boolean;
  icon?: typeof Bell;
  color?: string;
  action?: string;
}

const mockNotifications: Notification[] = [
  {
    id: 1,
    type: 'tour',
    title: 'Yeni Tur!',
    message: 'Kapadokya Kış Turu için erken rezervasyon fırsatı başladı.',
    time: '5 dakika önce',
    read: false,
    icon: Calendar,
    color: 'text-blue-500 bg-blue-100',
    action: 'Detayları Gör'
  },
  {
    id: 2,
    type: 'booking',
    title: 'Rezervasyon Onayı',
    message: 'İstanbul Boğaz Turu rezervasyonunuz onaylandı.',
    time: '1 saat önce',
    read: false,
    icon: MapPin,
    color: 'text-green-500 bg-green-100',
    action: 'Rezervasyonlarım'
  },
  {
    id: 3,
    type: 'promo',
    title: 'Özel Teklif',
    message: 'Size özel %20 indirim fırsatı!',
    time: '2 saat önce',
    read: false,
    icon: Gift,
    color: 'text-purple-500 bg-purple-100',
    action: 'Fırsatı Yakala'
  }
];

export default function NotificationCenter() {
  const [notifications, setNotifications] = useState(mockNotifications);
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState<'all' | 'unread'>('all');

  const unreadCount = notifications.filter(n => !n.read).length;

  const filteredNotifications = filter === 'all' 
    ? notifications 
    : notifications.filter(n => !n.read);

  const markAsRead = (id: number) => {
    setNotifications(notifications.map(n =>
      n.id === id ? { ...n, read: true } : n
    ));
  };

  const markAllAsRead = () => {
    setNotifications(notifications.map(n => ({ ...n, read: true })));
  };

  const deleteNotification = (id: number) => {
    setNotifications(notifications.filter(n => n.id !== id));
  };

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleEscape);
    }

    return () => window.removeEventListener('keydown', handleEscape);
  }, [isOpen]);

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="relative p-2 text-gray-600 hover:text-blue-600 focus:outline-none"
      >
        <Bell className="h-6 w-6" />
        {unreadCount > 0 && (
          <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs w-5 h-5 flex items-center justify-center rounded-full">
            {unreadCount}
          </span>
        )}
      </button>

      {isOpen && (
        <>
          <div 
            className="fixed inset-0 bg-black/20 backdrop-blur-sm z-40"
            onClick={() => setIsOpen(false)}
          />
          <div className="absolute right-0 mt-2 w-96 bg-white rounded-xl shadow-xl z-50 overflow-hidden">
            <div className="p-4 border-b border-gray-100">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-semibold text-gray-900">Bildirimler</h3>
                <div className="flex items-center space-x-2">
                  <select
                    value={filter}
                    onChange={(e) => setFilter(e.target.value as 'all' | 'unread')}
                    className="text-sm border-gray-200 rounded-lg focus:ring-blue-500"
                  >
                    <option value="all">Tümü</option>
                    <option value="unread">Okunmamış</option>
                  </select>
                  {unreadCount > 0 && (
                    <button
                      onClick={markAllAsRead}
                      className="text-sm text-blue-600 hover:text-blue-700"
                    >
                      Tümünü Okundu İşaretle
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="max-h-[calc(100vh-200px)] overflow-y-auto">
              {filteredNotifications.length > 0 ? (
                filteredNotifications.map((notification) => {
                  const Icon = notification.icon || Bell;
                  return (
                    <div
                      key={notification.id}
                      className={`p-4 hover:bg-gray-50 transition-colors ${
                        !notification.read ? 'bg-blue-50/50' : ''
                      }`}
                    >
                      <div className="flex items-start space-x-3">
                        <div className={`p-2 rounded-full ${notification.color}`}>
                          <Icon className="h-5 w-5" />
                        </div>
                        <div className="flex-1 min-w-0">
                          <div className="flex items-center justify-between">
                            <p className="text-sm font-medium text-gray-900">
                              {notification.title}
                            </p>
                            <button
                              onClick={() => deleteNotification(notification.id)}
                              className="text-gray-400 hover:text-red-500"
                            >
                              <X className="h-4 w-4" />
                            </button>
                          </div>
                          <p className="text-sm text-gray-600 mt-1">
                            {notification.message}
                          </p>
                          <div className="flex items-center justify-between mt-2">
                            <span className="text-xs text-gray-500">
                              {notification.time}
                            </span>
                            {notification.action && (
                              <button className="text-sm text-blue-600 hover:text-blue-700">
                                {notification.action}
                              </button>
                            )}
                          </div>
                          {!notification.read && (
                            <button
                              onClick={() => markAsRead(notification.id)}
                              className="text-xs text-blue-600 hover:text-blue-700 mt-2"
                            >
                              Okundu İşaretle
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="p-8 text-center text-gray-500">
                  <Bell className="h-8 w-8 mx-auto mb-2 text-gray-400" />
                  <p>Bildirim bulunmuyor</p>
                </div>
              )}
            </div>

            <div className="p-4 border-t border-gray-100 bg-gray-50">
              <button
                onClick={() => setIsOpen(false)}
                className="w-full text-center text-sm text-blue-600 hover:text-blue-700 font-medium"
              >
                Kapat
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}