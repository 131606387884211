import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Compass, MapPin, Camera, Coffee, Book, Users } from 'lucide-react';
import CitySearchBox from '../CitySearchBox';

const quickLinks = [
  { icon: MapPin, label: '81 İl', value: '81 şehir rehberi' },
  { icon: Camera, label: 'Fotoğraf Noktaları', value: '500+ özel nokta' },
  { icon: Coffee, label: 'Yerel Deneyimler', value: '300+ aktivite' },
  { icon: Users, label: 'Gezgin Önerileri', value: '1000+ öneri' }
];

export default function HeroSection() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (city: string) => {
    if (city) {
      navigate(`/city/${city.toLowerCase()}`);
    }
  };

  return (
    <section className="relative min-h-[85vh] flex items-center">
      {/* Background Image with Overlay */}
      <div className="absolute inset-0">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: 'url(https://images.unsplash.com/photo-1524231757912-21f4fe3a7200)',
            backgroundAttachment: 'fixed'
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-black/70 via-black/50 to-black/70" />
        </div>
      </div>

      {/* Content */}
      <div className="relative container mx-auto px-4">
        <div className="max-w-4xl mx-auto text-center">
          {/* Badge */}
          <div className="inline-flex items-center space-x-2 px-4 py-2 bg-white/10 backdrop-blur-sm rounded-full mb-8">
            <Compass className="h-5 w-5 text-blue-400 animate-pulse" />
            <span className="text-white/90">Türkiye'nin En Kapsamlı Seyahat Rehberi</span>
          </div>

          {/* Title */}
          <h1 className="text-5xl md:text-6xl font-bold text-white mb-6 leading-tight">
            Türkiye'nin Tüm<br />Güzelliklerini Keşfedin
          </h1>

          {/* Description */}
          <p className="text-xl text-white/90 mb-12 max-w-2xl mx-auto">
            81 ilin benzersiz tarihi, kültürü ve doğal güzellikleri için profesyonel rehberiniz
          </p>

          {/* Search Box Container */}
          <div className="relative z-50 mb-12">
            <CitySearchBox value={searchTerm} onChange={handleSearch} />
          </div>

          {/* Quick Stats */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 max-w-3xl mx-auto">
            {quickLinks.map((link, index) => (
              <div
                key={index}
                className="px-4 py-3 bg-white/10 backdrop-blur-sm rounded-xl hover:bg-white/15 transition-colors"
              >
                <link.icon className="h-6 w-6 text-blue-400 mx-auto mb-2" />
                <h3 className="text-white font-medium text-sm mb-1">{link.label}</h3>
                <p className="text-white/80 text-xs">{link.value}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}