import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { countries, Country } from '../data/countries';
import { saveImageToLocalStorage, getImageFromLocalStorage, removeImageFromLocalStorage } from '../utils/imageUtils';

interface ProfileFormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  city: string;
}

export function useProfile() {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [country, setCountry] = useState<Country>(countries[0]);
  const [profile, setProfile] = useState<any>(null);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [formData, setFormData] = useState<ProfileFormData>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    city: ''
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') || 'null');
    if (!currentUser) {
      navigate('/login');
      return;
    }
    
    setProfile(currentUser);
    setFormData({
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      email: currentUser.email,
      phone: currentUser.phone.replace(country.dialCode, ''),
      city: currentUser.city
    });

    const savedImage = getImageFromLocalStorage(currentUser.id);
    if (savedImage) {
      setProfileImage(savedImage);
    }
  }, [navigate]);

  const handleImageSelect = async (file: File) => {
    try {
      const imageUrl = await saveImageToLocalStorage(profile.id, file);
      setProfileImage(imageUrl as string);
      setSuccessMessage('Profil fotoğrafı başarıyla güncellendi.');
    } catch (error) {
      setErrorMessage('Profil fotoğrafı yüklenirken bir hata oluştu.');
    }
  };

  const handleImageRemove = () => {
    removeImageFromLocalStorage(profile.id);
    setProfileImage(null);
    setSuccessMessage('Profil fotoğrafı kaldırıldı.');
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      const updatedUser = {
        ...profile,
        ...formData,
        phone: `${country.dialCode}${formData.phone}`
      };

      const users = JSON.parse(localStorage.getItem('users') || '[]');
      const updatedUsers = users.map((user: any) =>
        user.id === profile.id ? updatedUser : user
      );
      
      localStorage.setItem('users', JSON.stringify(updatedUsers));
      localStorage.setItem('currentUser', JSON.stringify(updatedUser));
      
      setProfile(updatedUser);
      setIsEditing(false);
      setSuccessMessage('Profiliniz başarıyla güncellendi!');
      
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      setErrorMessage('Profil güncellenirken bir hata oluştu.');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  };

  return {
    isEditing,
    setIsEditing,
    profile,
    profileImage,
    formData,
    setFormData,
    country,
    setCountry,
    successMessage,
    errorMessage,
    handleImageSelect,
    handleImageRemove,
    handleSubmit
  };
}