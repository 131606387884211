import React from 'react';
import { MapPin, Star } from 'lucide-react';
import { CityData } from '../../../types';

interface CityListItemProps {
  city: CityData;
}

export default function CityListItem({ city }: CityListItemProps) {
  const attractionsCount = Object.values(city.attractions).flat().length;

  return (
    <div className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow p-4">
      <div className="flex items-center space-x-4">
        <img
          src={city.images.main}
          alt={city.name}
          className="w-24 h-24 rounded-lg object-cover"
        />
        <div className="flex-1 min-w-0">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-semibold text-gray-900">{city.name}</h3>
            <span className="text-sm text-blue-600 bg-blue-50 px-3 py-1 rounded-full">
              {city.region}
            </span>
          </div>
          <p className="mt-1 text-sm text-gray-500 line-clamp-2">{city.description}</p>
          <div className="mt-2 flex items-center space-x-4 text-sm text-gray-600">
            <div className="flex items-center">
              <MapPin className="h-4 w-4 mr-1" />
              <span>{attractionsCount} yer</span>
            </div>
            <div className="flex items-center text-yellow-500">
              <Star className="h-4 w-4 mr-1 fill-current" />
              <span className="text-gray-600">4.8</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}